export default {
    hiddenFilter: false,
    list: {
        color: "blue lighten-3",
        // btn: "Завершить аудит",
        dense: true,
        items: [

        ]
    },

    findItems: [

        {
            type: 'select',
            name: 'id_object',
            title: 'Объект',
            items: [],
            itemText: 'name',
            itenValue: 'id',
            model: null,
            filter: true,
        },
        {
            type: 'checkbox',
            name: 'without_grade',
            title: 'Аудиты без оценок',
            model: null,
            filter: true,
        },
    ]
}