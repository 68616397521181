<template>
  <div>
    <!-- <Tree :tree="tree" /> -->
    <Form
      :resetFormAddEdit="resetFormAddEdit"
      :loadingAction="loading"
      class="mt-4"
      :formItems="formItems"
      @getActionBtn="getActionBtn"
    />
  </div>
</template>
<script>
import Helper from "./../../../plugins/helper";
import ParamsAddObject from "./paramsAddObject";
import ParamsCommon from "./../../paramsCommon";
import catalogs from "./../../../plugins/catalogs";
// import Tree from "./../../units/tree/tree.vue";
import Form from "./../../units/form/form.vue";
export default {
  components: { /*Tree,*/ Form },
  data() {
    return { ...ParamsAddObject, ...ParamsCommon };
  },
  mounted() {
    this.setTitleAppBar("Добавить обьект");
  },
  methods: {
    ...Helper,
    ...catalogs,
    async add(name, data) {
      data = data.reduce((acc, el) => {
        return {
          ...acc,
          ...{
            [el.name]:
              el.multi && el.model ? el.model.map((m) => m.id) : el.model,
          },
        };
      }, {});
      this.loading = true;
      console.log(name, data);
      const newId = (await this.addToCatalog(name, data)).data.new_id_object;
      console.log(newId);
      // await this.get();
      this.loading = false;
      this.resetFormAddEdit = !this.resetFormAddEdit;
      this.$router.push({ path: `object?id=${newId}&action=edit` });
    },

    async getActionBtn(v) {
      console.log(v);
      await this.add("objects", v);
      // const newIdAudit = 700;
      // location.href = `audit?id=${newIdAudit}&action=edit`;
      // this.$router.push({
      //   path: `audit?id=${newIdAudit}&action=edit`,
      // });
    },
  },
};
</script>
