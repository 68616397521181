<template>
  <div>
    <v-navigation-drawer
      v-model="activeModel"
      fixed
      bottom
      light
      floating
      width="456"
      class="new-class-bottom"
    >
      <div style="position: relative">
        <v-btn class="close-btn-bottom" icon @click="activeModel = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <div
          style="
            position: sticky;
            top: 0;
            z-index: 1;
            border-bottom: 1px solid #ededed !important;
          "
          class="pa-3 white"
        >
          <v-row
            no-gutters
            justify="space-between"
            align="center"
            style="position: relative"
          >
            <v-skeleton-loader
              min-width="200"
              v-if="loadingBottom"
              type="heading"
            ></v-skeleton-loader>
            <v-col class="header-bottom">
              <h3 style="overflow: hidden" v-if="!loadingBottom">
                {{ dataItems.label }}
              </h3>
            </v-col>
            <v-col cols="auto">
              <v-btn
                v-if="!loadingBottom && showSave"
                @click="
                  $emit(
                    'apply',
                    modelList.map((m) => m.id),
                    dataItems.items
                  )
                "
                class="new-btn"
                color="success"
                text
                >Сохранить</v-btn
              >
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-text-field
              v-if="!loadingBottom"
              hide-details
              outlined
              v-model="search"
              dense
              append-icon="mdi-magnify"
              class="mt-2"
            >
            </v-text-field>
          </v-row>
        </div>

        <v-skeleton-loader
          class="px-3"
          min-width="200"
          v-if="loadingBottom"
          type="text@3"
        ></v-skeleton-loader>
        <v-data-table
          :group-by="[groping]"
          v-if="!loadingBottom"
          no-data-text="Данных нет"
          no-results-text="Данных нет"
          v-model="modelList"
          selectable-key="id"
          :items-per-page="1000"
          :search="search"
          :items="dataItems.items"
          @click:row="selectRow"
          :headers="[
            {
              value: 'name',
              text: 'name',
              filterable: true,
              align: 'start',
              cellClass: 'textOverflow',
            },
          ]"
          :item-class="itemClass"
          hide-default-footer
          hide-default-header
          mobile-breakpoint="0"
        >
          <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
            <th colspan="2">
              <v-icon @click="toggle"
                >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
              {{ items[0][groping] }}
            </th>
          </template>

          <template v-if="dataItems.text" v-slot:[`item.name`]="props">
            <div>
              <v-textarea auto-grow v-model="props.item.name"> </v-textarea>
            </div>
          </template>
        </v-data-table>
        <!-- <v-list v-if="!loadingBottom">
          <v-list-item-group
            v-model="modelList"
            color="primary"
            :multiple="dataItems.multi"
          >
            <v-list-item
              :value="item.id"
              v-for="item in dataItems.items"
              :key="item.id"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list> -->
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import ParamsBottom from "./paramsBottom";
export default {
  props: ["active", "dataItems", "loadingBottom", "showSave", "groping"],
  data() {
    return { ...ParamsBottom };
  },
  methods: {
    itemClass() {
      // if (this.modelList.includes(item.id)) return "select-color";
    },
    selectRow(row, e) {
      console.log(row);
      if (this.dataItems.multi) {
        if (["admin", "auditor"].includes(this.$auth.roles()))
          e.select(!e.isSelected);
      } else {
        if (["admin", "auditor"].includes(this.$auth.roles())) e.select(true);

        this.modelList = [e.item];
      }
    },
  },
  watch: {
    dataItems: {
      handler(v) {
        this.modelList = v.model;
        this.search = "";
      },
      deep: true,
    },
    active(v) {
      this.activeModel = v;
    },
    activeModel(v) {
      if (!v) this.$emit("initActive", false);
    },
  },
};
</script>

<style scoped>
@media (max-width: 1263px) {
  ::v-deep .close-btn-bottom {
    position: fixed;
    right: 15px;
    top: 0;
    z-index: 3;
    background: white;
  }

  ::v-deep .v-navigation-drawer__content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 45px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    background: white;
  }

  ::v-deep .new-class-bottom {
    background: none !important;
    box-shadow: none !important;
  }
}
@media (min-width: 1264px) {
  ::v-deep .close-btn-bottom {
    position: fixed;
    right: 0px;
    top: 15px;
    z-index: 3;
    background: white;
  }
  ::v-deep .v-navigation-drawer__content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 45px;
    background: white;
    border-right: 1px solid #eee;
  }
  ::v-deep .new-class-bottom {
    background: none !important;
    box-shadow: none !important;
  }
}

::v-deep .v-navigation-drawer--bottom.v-navigation-drawer--is-mobile {
  max-height: 80%;
  top: auto;
  bottom: 0;
  min-width: 100%;
}
::v-deep .header-bottom {
  overflow: hidden;
  white-space: nowrap;
}
::v-deep .textOverflow {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  text-align: left !important;
  cursor: pointer;
}
::v-deep .header-bottom h3 {
  text-overflow: ellipsis;
}
::v-deep .select-color {
  color: #1976d2 !important;
  background: rgba(25, 118, 210, 0.1) !important;
}

/* ::v-deep .theme--light.v-icon {
  color: blue !important;
} */
/* ::v-deep .v-input--selection-controls__ripple.green--text {
  color: blue !important;
} */

::v-deep .theme--light.v-data-table tbody tr.v-data-table__selected {
  color: #1976d2 !important;
  background: rgba(25, 118, 210, 0.1) !important;
}
</style>