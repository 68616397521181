export default {
    registry: {
        items: [],
        textVal: {
            'date': 'Дата',
            'object': 'Объект',
            'old_manager': 'Старый менеджер',
            'new_manager': 'Новый менеджер',
            'event_object': 'Событие'
        },
        get headers() {
            return Object.keys(this.items.length ? this.items[0] : {}).map((m) => {
                return {
                    value: m,
                    text: this.textVal[m],
                    align: 'start'
                }
            })
        },
        add: {
            data: []
        }
    }
}