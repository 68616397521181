<template>
  <div>
    <!-- <Tree :tree="tree" /> -->
    <Form
      class="mt-4"
      :resetFormAddEdit="resetFormAddEdit"
      :loadingAction="loading"
      :formItems="generateItemsForm"
      @getActionBtn="addAudit"
    />
  </div>
</template>
<script>
import Helper from "./../../../plugins/helper";
import ParamsAddAudit from "./paramsAddAudit";
import ParamsCommon from "./../../paramsCommon";
import catalogs from "./../../../plugins/catalogs";
// import Tree from "./../../units/tree/tree.vue";
import Form from "./../../units/form/form.vue";
export default {
  components: { /*Tree,*/ Form },
  data() {
    return { ...ParamsAddAudit, ...ParamsCommon };
  },
  mounted() {
    this.setTitleAppBar("Добавить аудит");
  },
  computed: {
    generateItemsForm() {
      return this.initForm();
    },
  },
  methods: {
    ...Helper,
    ...catalogs,
    // mounted() {
    //   this.initForm();
    // },
    initForm() {
      this.formItems.items.forEach((m) => {
        let cur = m;
        if (cur.name === "id_object") {
          cur.model = null;
          cur.findGetSubCatalog = {
            id: null,
            name: null,
            id_user: ["manager", "client", "external_auditor"].includes(
              this.$auth.roles()
            )
              ? this.$auth.user().id
              : null,
          };
        }
      });
      console.log("formItems", this.formItems);
      return this.formItems;
    },
    async add(name, data) {
      data = data.reduce((acc, el) => {
        return {
          ...acc,
          ...{
            [el.name]: el.multi ? el.model.map((m) => m.id) : el.model,
          },
        };
      }, {});
      this.loading = true;
      const nnewId = (await this.addToCatalog(name, data)).data;
      this.loading = false;
      this.resetFormAddEdit = !this.resetFormAddEdit;
      this.$router.push({
        path: `audit?id=${nnewId}&action=edit`,
      });
    },
    async addAudit(v) {
      console.log(v);
      await this.add("audits", v);
    },
  },
};
</script>
