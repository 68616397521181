export default {
    progressPDF: 0,
    permissClientUnit: [
        "качество",
        "охрана труда",
        "стандарт",
        "сотрудник",
        "отзыв клиента",
        "требования клиента",
    ],
    filterDynamic: [
        {
            type: 'date',
            model: null,
            name: 'audit_date',
            title: 'Даты аудитов',
            multi: true,
            filter: true,
            sm: 6,
        },
    ],
    loadFinding: false,
    collapseGroup: false,
    id: 700,
    action: '',
    fullDataAudit: null,
    idUnitsEvaluation: '',
    activeActionItems: false,
    actionItems: {
        label: '',
        multi: false,
        tables: [{
            name: '',
            items: []
        }]
    },
    reportDynamic: [],
    report: [],
    reportNew: [],
    type: '',
    idEvaluation: '',
    unitsEvaluation: {},
    evaluation: {},
    formItems: {
        items: [
            // {
            //     type: 'select',
            //     name: 'areas',

            //     label: 'Участок',
            //     items: [{ id: 1, name: 'no' }, { id: 2, name: 'ok' }],
            //     model: null,
            //     itemText: 'name',
            //     required: true,

            // },
            // {
            //     type: 'select',
            //     name: 'surfaces',
            //     label: 'Поверхность',
            //     items: [{ id: 1, name: 'no' }, { id: 2, name: 'ok' }],
            //     model: null,
            //     itemText: 'name',
            //     required: true,
            // },
            // {
            //     type: 'select',
            //     name: 'remarks',
            //     label: 'Замечание',
            //     items: [{ id: 1, name: 'no' }, { id: 2, name: 'ok' }],
            //     model: null,
            //     itemText: 'name',
            //     required: true,
            // },
            // {
            //     type: 'textarea',
            //     name: 'details',
            //     label: 'Пожробности',
            //     model: null,
            //     itemText: 'name',
            //     required: true,
            // },
        ]
    },

    tree: {
        // color: '',
        // marginB: 'mb-2',
        // items: [
        //     {
        //         name: 'idAudit',
        //         items: [
        //             {
        //                 name: 'leftIdAudit',
        //                 title: 'ID аудита',
        //             },
        //             {
        //                 name: 'rightIdAudit',
        //                 title: '134',
        //             },

        //         ],
        //         color: 'custom_orange',
        //     },
        // ]
    },
    listUnitsEvaluation: {
        color: 'blue lighten-3',
        dense: true,
        items: []
    },
    listRecords: {
        name: 'records',
        color: 'blue lighten-3',
        dense: true,
        items: []
    },
    listOpinions: {
        color: 'blue lighten-3',
        dense: true,
        items: []
    },
    dateOpen: {
        color: 'blue lighten-3',
        dense: true,
        items: []
    },
    listSectionRecords: {
        color: 'blue lighten-3',
        items: [
            {
                name: `records`,
                title: 'Записи по обьекту',
                color: 'custom_yellow',
                icon: 'mdi-pencil',
            },
            {
                name: `records_area`,
                title: 'Записи по участкам',
                color: 'custom_yellow',
                icon: 'mdi-pencil',
            },
            {
                name: `records_employees`,
                title: 'Записи по сотрудникам',
                color: 'custom_yellow',
                icon: 'mdi-pencil',
            },
            {
                name: `records_storeroom`,
                title: 'Записи по кладовым',
                color: 'custom_yellow',
                icon: 'mdi-pencil',
            },
            {
                name: `records_workspaces`,
                title: 'Записи по рабочим местам',
                color: 'custom_yellow',
                icon: 'mdi-pencil',
            },

        ]
    },
    list:
    {
        color: 'blue lighten-3',
        items: [
            {
                name: `audit`,
                title: 'Добавить запись',
                color: 'custom_blue',
                icon: 'mdi-plus',
            },
            {
                name: 'addOpinionAudit',
                title: 'Добавить мнение аудитора',
                color: 'custom_blue',
                icon: 'mdi-plus',
            },
            {
                name: 'listRecords',
                title: 'Редактировать запись',
                color: 'custom_yellow',
                icon: 'mdi-pencil',
            },
            {
                name: 'addObject',
                title: 'Редактировать мнение аудитора',
                color: 'custom_yellow',
                icon: 'mdi-pencil',
            },

        ]
    }
}