// import Helper from "../../../plugins/helper";
export default {
    activeActionItems: false,
    formItems: {
        items: [
            {
                type: 'text',
                name: 'name',

                label: 'Название',
                items: [],
                model: null,
                itemText: 'name',
                required: true,

            },
            {
                type: 'select',
                name: 'id_type_object',

                label: 'Тип объекта',
                model: null,
                itemText: 'name',
                required: true,
            },
            {
                type: 'textarea',
                name: 'description',

                label: 'Описание',
                items: [],
                model: null,
                itemText: 'name',
                required: false,
                block: true,
            },

            {
                type: 'select',
                name: 'id_employee',
                label: 'Сотрудники',
                items: [],
                model: null,

                multi: true,
                itemText: (s) => `${s.name} ${s.sername} ${s.secondname}`,
                required: false,
                // block: true,
            },

            {
                type: 'select',
                name: 'id_workspace',
                label: 'Рабочие места',
                items: [],
                model: null,
                multi: true,
                itemText: 'name',
                required: false,
                // block: true,
            },



            {
                type: 'select',
                name: 'id_area',
                label: 'Участки',
                items: [],
                model: null,
                multi: true,
                itemText: 'name',
                required: false,
                // block: true,
            },


            {
                type: 'select',
                name: 'id_storeroom',
                label: 'Кладовые',
                items: [],
                model: null,
                multi: true,
                itemText: 'name',
                required: false,
                // block: true,
            },
            {
                type: 'select',
                name: 'id_evaluation',
                label: 'Что оцениваем',
                defaultModel: true,
                items: [],
                model: null,
                multi: true,
                itemText: 'name',
                required: false,
                block: true,
            },





        ]
    },
    tree: {
        color: '',
        marginB: 'mb-2',
        items: [
            {
                name: 'idAudit',
                items: [
                    {
                        name: 'leftIdAudit',
                        title: 'ID аудита',
                    },
                    {
                        name: 'rightIdAudit',
                        title: '134',
                    },

                ],
                color: 'custom_orange',
            },
            // {
            //     name: 'addRecord',
            //     border: true,
            //     items: [
            //         {
            //             name: 'leftAddRecord',
            //             title: 'Добавление записи',
            //         },
            //         {
            //             name: 'rightAddRecord',
            //             title: 'Качество',
            //         },

            //     ],
            //     color: 'custom_yellow',
            // },
            // {
            //     name: 'evaluation',
            //     border: false,
            //     items: [
            //         {
            //             name: 'leftEvaluation',
            //             title: 'Санузел',
            //         },
            //         // {
            //         //     name: 'rightAddRecord',
            //         //     title: 'Качество',
            //         // },

            //     ],
            //     color: 'custom_yellow',
            // },
        ]
    }
}