<template>
  <div style="position: relative" class="h-100">
    <div style="height: auto">
      <Tree
        v-if="(fullDataObject && fullDataObject.length) || loading"
        class="stickyTree"
        @getBtnClick="prev"
        :tree="tree"
        :loading="loading"
      />
      <!-- <v-row
        v-if="!loading"
        no-gutters
        justify="start"
        align="center"
        class="mx-5 mb-2"
      >
        <v-col class="grey--text ml-2 subtitle-2" cols="auto"> Статус: </v-col>
        <v-col
          :class="{
            'subtitle-2': true,
            'ml-2': true,
            'green--text text--darken-3':
              fullDataObject &&
              fullDataObject.length &&
              fullDataObject[0].status_object === 'Запущен',
            'red--text text--darken-3':
              fullDataObject &&
              fullDataObject.length &&
              fullDataObject[0].status_object === 'Закрыт',
            'blue--text text--darken-3':
              fullDataObject &&
              fullDataObject.length &&
              fullDataObject[0].status_object === 'Заморозка',
            'orange--text text--darken-3':
              fullDataObject &&
              fullDataObject.length &&
              fullDataObject[0].status_object === 'в УПР',
          }"
          cols="auto"
        >
          {{
            fullDataObject && fullDataObject.length
              ? fullDataObject[0].status_object
              : ""
          }}
        </v-col>
      </v-row> -->

      <v-row
        v-if="!loading"
        no-gutters
        justify="start"
        align="center"
        class="mx-5"
      >
        <v-col
          v-if="
            fullDataObject &&
            fullDataObject.length &&
            fullDataObject[0].managers.length
          "
          class="grey--text ml-2 subtitle-2 mb-2"
          cols="auto"
        >
          Менеджер:
        </v-col>
        <v-col
          v-for="m in fullDataObject && fullDataObject.length
            ? fullDataObject[0].managers
            : []"
          :key="m.name"
          cols="auto"
          :class="{
            'ml-2': true,
            'blue--text': true,
            'subtitle-2': true,
            'mb-2': true,
          }"
        >
          <v-chip small color="blue lighten-4">
            {{ m.sername + " " + m.name }}</v-chip
          >
        </v-col>

        <v-col
          v-if="
            fullDataObject &&
            fullDataObject.length &&
            !fullDataObject[0].managers.length
          "
          cols="auto"
          :class="{ 'grey--text': true, 'subtitle-2': true, 'ml-2': true }"
          >Нет менедежра</v-col
        >
      </v-row>

      <ActionList
        :loadingAction="loadSubCatalog"
        :loading="loading"
        v-if="
          this.$route.query.action &&
          this.$route.query.action === 'edit' &&
          fullDataObject &&
          fullDataObject.length
        "
        @getClickListItem="actionEditObject"
        :list="list"
      />
      <!-- <ActionList
      v-if="
        (this.$route.query.action &&
          this.$route.query.action === 'addRecord' &&
          this.$route.query.idUnitsEvaluation &&
          !this.$route.query.idEvaluation) ||
        (this.$route.query.action &&
          this.$route.query.action === 'addRecord' &&
          !this.$route.query.idUnitsEvaluation &&
          !this.$route.query.idEvaluation)
      "
      @getClickListItem="getClickListItemUnitsEvaluation"
      :list="listUnitsEvaluation"
    /> -->

      <Form
        v-if="
          this.$route.query.action &&
          ['addEmployee', 'addWorkspace', 'addArea', 'addStoreroom'].includes(
            this.$route.query.action
          )
        "
        :formItems="formItems"
      />
    </div>

    <Bottom
      :loadingBottom="loadingBottom"
      @initActive="initActive"
      :active="activeActionItems"
      :dataItems="actionItems"
      @apply="applyAddItem"
      :groping="
        actionItems && actionItems.name === 'evaluations'
          ? 'unit_evaluation'
          : ''
      "
      :showSave="['admin', 'auditor'].includes($auth.roles())"
    />
    <!-- <vue-context ref="menu">
      <ul>
        <li @click="onClick($event.target.innerText)">Option 1</li>
        <li @click="onClick($event.target.innerText)">Option 2</li>
      </ul>
    </vue-context> -->

    <ErrorOBjectsNo
      v-if="!(fullDataObject && fullDataObject.length) && !loading"
    />
  </div>
</template>

<script>
import Helper from "./../../../plugins/helper";
import ParamsObject from "./paramsObject";
import Tree from "./../../units/tree/tree.vue";
import ActionList from "./../../units/actionList/actionList.vue";
import Form from "./../../units/form/form.vue";
import ParamsCommon from "./../../paramsCommon";
import catalogs from "./../../../plugins/catalogs";
import Bottom from "./../../units/bottom/bottom.vue";
import ErrorOBjectsNo from "./../../views/errors/notObject.vue";
export default {
  components: {
    Tree,
    ActionList,
    Form,
    Bottom,
    ErrorOBjectsNo,
  },
  data() {
    return { ...ParamsObject, ...ParamsCommon };
  },
  async mounted() {
    await this.initData();
  },
  methods: {
    ...Helper,
    ...catalogs,
    /**
     * ПРименение добавления обьекта
     */
    async applyAddItem(v, itm) {
      console.log("this.fullDataObject[0]", this.fullDataObject[0]);
      this.activeActionItems = false;
      for (let key in this.fullDataObject && this.fullDataObject.length
        ? this.fullDataObject[0]
        : {}) {
        const cur = this.fullDataObject[0][key];

        if (Array.isArray(cur)) {
          if (key === this.actionItems.name) {
            this.fullDataObject[0][key] = v;
          } else {
            this.fullDataObject[0][key] = cur.map((m) => m.id);
          }
        } else if (key === "status_object" && key === this.actionItems.name) {
          this.fullDataObject[0][key] = v[0];
          console.log("v[0]", v);
        } else if (key === "status_object") {
          this.fullDataObject[0][key] = (
            await this.getCatalog("status_object")
          ).data.items.find(
            (f) => f.name.toLowerCase() === cur.toLowerCase()
          ).id;
          // console.log(
          //   "v[0]",
          //   (await this.getCatalog("status_object")).data.items.find(
          //     (f) => f.name.toLowerCase() === cur.toLowerCase()
          //   ).id
          // );
        } else if (key === "name" && this.actionItems.name === "objects") {
          this.fullDataObject[0][key] = itm[0].name;
          console.log(this.fullDataObject[0][key], "UPDATE", v, itm);
        }
        // console.log("v", v, "cur", cur);
      }
      this.loadSubCatalog = true;
      await this.updateToCatalog(
        "objects",
        this.fullDataObject && this.fullDataObject.length
          ? this.fullDataObject[0]
          : {},
        this.id
      );
      await this.initData();
      this.loadSubCatalog = false;

      console.log(v, this.actionItems.name, this.fullDataObject);
    },
    /**
     * ПОлучене данных по обьекту из БД
     */
    async getDataFromDB() {
      this.loading = true;
      this.fullDataObject = (
        await this.findCatalog("objects", {
          id: this.id,
          name: null,
          id_user: ["manager", "client", "external_auditor"].includes(
            this.$auth.roles()
          )
            ? this.$auth.user().id
            : null,
        })
      ).data.items;
      this.loading = false;
    },
    initActive(v) {
      this.activeActionItems = v;
    },
    async actionEditObject(v) {
      this.activeActionItems = true;
      this.loadingBottom = true;
      const items = (await this.getCatalog(v.model)).data.items;
      this.loadingBottom = false;
      this.actionItems.name = v.model;
      this.actionItems.label = v.title;
      this.actionItems.multi = v.multi;
      if (v.model === "objects") this.actionItems.text = true;
      else this.actionItems.text = undefined;
      this.actionItems.model =
        this.fullDataObject && this.fullDataObject.length
          ? Array.isArray(this.fullDataObject[0][v.model])
            ? this.fullDataObject[0][v.model]
            : items.filter((f) => f.name === this.fullDataObject[0][v.model])
          : null; //.map((m) => m.id);
      this.actionItems.items =
        v.model === "employees"
          ? items.map((m) => {
              return {
                ...m,
                name: `${m.name} ${m.sername} ${m.secondname}`,
              };
            })
          : v.model === "objects"
          ? items.filter((f) => f.id === Number(this.id))
          : items;
    },
    async initData() {
      this.id = this.$route.query.id;
      this.action = this.$route.query.action;
      await this.getDataFromDB();
      //   this.idUnitsEvaluation = this.$route.query.idUnitsEvaluation;
      //   this.idEvaluation = this.$route.query.idEvaluation;
      this.setTitleAppBar("Объект " + this.id);
      this.initActionList();
      this.initTree();
    },
    /**
     * Кнлпка назад
     */
    prev() {
      this.$router.go(-1);
    },

    /**
     * Инициализация активного списка
     */
    initActionList() {
      const colorsStatusObject = {
        Запущен: "green lighten-4",
        Закрыт: "red lighten-4",
        Заморозка: "blue lighten-4",
        "в УПР": "orange lighten-4",
      };
      const self = this;
      if (this.fullDataObject && this.fullDataObject.length)
        this.list = {
          color: "blue lighten-3",
          // btn: "Завершить аудит",
          items: [
            {
              name: "name",
              title: "Название",
              color: "custom_grey",
              model: "objects",
              icon: "mdi-pencil",
              multi: false,
              show: ["admin", "auditor", "rpo"].includes(this.$auth.roles()),
            },
            {
              name: "id_status_object",
              title:
                this.fullDataObject && this.fullDataObject.length
                  ? this.fullDataObject[0].status_object
                  : "",
              color: `${
                colorsStatusObject[
                  this.fullDataObject && this.fullDataObject.length
                    ? this.fullDataObject[0].status_object
                    : ""
                ]
              }`,
              model: "status_object",
              icon: "mdi-pencil",
              multi: false,
            },
            {
              get name() {
                return `object?id=${self.id}&action=addEmployee`;
              },
              title: "Сотрудники",
              color: "custom_yellow",
              model: "employees",
              icon: "mdi-pencil",
              multi: true,
            },
            {
              get name() {
                return `object?id=${self.id}&action=addWorkspace`;
              },
              title: "Рабочие места",
              color: "custom_yellow",
              model: "workspaces",
              icon: "mdi-pencil",
              multi: true,
            },
            {
              get name() {
                return `object?id=${self.id}&action=addArea`;
              },
              title: "Участки",
              color: "custom_yellow",
              model: "areas",
              icon: "mdi-pencil",
              multi: true,
            },
            {
              get name() {
                return `object?id=${self.id}&action=addStoreroom`;
              },
              title: "Кладовые",
              color: "custom_yellow",
              model: "storerooms",
              icon: "mdi-pencil",
              multi: true,
            },
            {
              get name() {
                return `object?id=${self.id}&action=addEvaluation`;
              },
              title: `"Что оцениваем"`,
              color: "custom_yellow",
              model: "evaluations",
              icon: "mdi-pencil",
              multi: true,
            },

            //   {
            //     name: "listRecords",
            //     title: "Редактировать запись",
            //     color: "custom_yellow",
            //     icon: "mdi-pencil",
            //   },
            //   {
            //     name: "addObject",
            //     title: "Редактировать мнение аудитора",
            //     color: "custom_yellow",
            //     icon: "mdi-pencil",
            //   },
          ],
        };
    },

    /**
     * Инициализация дерева заголовка
     */
    initTree() {
      //const action = this.$route.query.action;
      this.tree = {
        color: "",
        marginB: "mb-2",
        items: [
          {
            name: "idObject",
            items: [
              {
                name: "btnPrev",
                type: "btn",
                title: "",
                icon: "mdi-chevron-left",
              },
              {
                name: "leftIdObject",
                title:
                  this.fullDataObject && this.fullDataObject.length
                    ? this.fullDataObject[0].name
                    : null,
              },
              {
                name: "leftIdObject_1",
                title: "",
              },
            ],
            color: "custom_orange",
          },
        ],
      };
      //   switch (this.action) {
      //     case "addRecord":
      //       //Инициализируем лерерво заголовокв
      //       this.tree.items.push({
      //         name: this.action,
      //         border: true,
      //         items: [
      //           {
      //             name: this.action + "_0",
      //             title: "Добавление записи",
      //           },
      //         ],
      //         color: "custom_yellow",
      //       });
      //       //Добавляем в дерево заголовков "БЛОК ОЦЕНКИ"
      //       if (this.action === "addRecord" && this.idUnitsEvaluation) {
      //         this.tree.items[1].items.push({
      //           name: this.action + "_1",
      //           title: this.unitsEvaluation.name,
      //         });
      //       }
      //       //Добавляем в дерево заголовков "ЧТО ОЦЕНИВАЕМ"
      //       if (this.action === "addRecord" && this.idEvaluation) {
      //         this.tree.items.push({
      //           name: this.action + this.idEvaluation,
      //           items: [
      //             {
      //               name: this.action + this.idEvaluation + "_0",
      //               title: this.evaluation.name,
      //             },
      //           ],
      //           color: "custom_yellow",
      //         });
      //       }
      //       break;
      //     case "addOpinionAudit":
      //       //Инициализируем лерерво заголовокв
      //       this.tree.items.push({
      //         name: this.action,
      //         border: true,
      //         items: [
      //           {
      //             name: this.action + "_0",
      //             title: "Добавление мнения аудитора",
      //           },
      //         ],
      //         color: "custom_yellow",
      //       });
      //       break;
      //     default:
      //       break;
      //   }
    },
  },
};
</script>