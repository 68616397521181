var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"startPage",staticStyle:{"position":"relative"}},[((_vm.fullDataAudit && _vm.fullDataAudit.length) || _vm.loading)?_c('Tree',{staticClass:"stickyTree",attrs:{"tree":_vm.tree,"loading":_vm.loading},on:{"getBtnClick":_vm.getBtnClick,"getLongBtnClick":_vm.getLongBtnClick}}):_vm._e(),(
      this.$route.query.action &&
      this.$route.query.action === 'edit' &&
      _vm.fullDataAudit &&
      _vm.fullDataAudit.length
    )?_c('ActionList',{attrs:{"loading":_vm.loading,"loadingAction":false,"list":_vm.list},on:{"getActionBtnActionList":_vm.getActionBtnActionList,"getClickListItem":_vm.actionAudit}}):_vm._e(),(
      (this.$route.query.action &&
        this.$route.query.action === 'addRecord' &&
        this.$route.query.idUnitsEvaluation &&
        !this.$route.query.idEvaluation) ||
      (this.$route.query.action &&
        this.$route.query.action === 'addRecord' &&
        !this.$route.query.idUnitsEvaluation &&
        !this.$route.query.idEvaluation)
    )?_c('ActionList',{attrs:{"loading":_vm.loading,"loadingAction":false,"list":_vm.listUnitsEvaluation},on:{"getClickListItem":_vm.getClickListItemUnitsEvaluation}}):_vm._e(),(
      this.$route.query.action &&
      this.$route.query.action === 'listRecords' &&
      !this.$route.query.type
    )?_c('ActionList',{attrs:{"loading":_vm.loading,"loadingAction":false,"list":_vm.listSectionRecords},on:{"getClickListItem":_vm.actionSectionRecord}}):_vm._e(),(
      this.$route.query.action &&
      this.$route.query.action === 'listRecords' &&
      this.$route.query.type
    )?_c('ActionList',{attrs:{"loading":_vm.loading,"loadingAction":_vm.loadingAction,"loadAddRaw":_vm.loadAddRaw,"dialogRemove":_vm.dialogRemove,"list":_vm.listRecords,"showRemove":['admin', 'auditor'].includes(_vm.$auth.roles())},on:{"getClickListItem":_vm.choiseRecord,"closeDialogRemove":_vm.closeDialogRemove,"remove":_vm.removeItem,"openDialogRemove":_vm.openDialogRemove}}):_vm._e(),(
      this.$route.query.action && this.$route.query.action === 'listOpinions'
    )?_c('ActionList',{attrs:{"loading":_vm.loading,"loadingAction":_vm.loadingAction,"list":_vm.listOpinions},on:{"getClickListItem":_vm.choiseOpinion}}):_vm._e(),(this.$route.query.action && this.$route.query.action === 'dateOpen')?_c('ActionList',{attrs:{"loading":_vm.loading,"loadingAction":_vm.loadingAction,"list":_vm.dateOpen},on:{"getClickListItem":_vm.choiseDateOpen}}):_vm._e(),(
      (_vm.formItems.items.length &&
        this.$route.query.action &&
        this.$route.query.action === 'addRecord' &&
        this.$route.query.idUnitsEvaluation &&
        this.$route.query.idEvaluation) ||
      (_vm.formItems.items.length &&
        this.$route.query.action &&
        this.$route.query.action === 'addOpinionAudit')
    )?_c('Form',{attrs:{"loading":_vm.loading,"loadingAction":_vm.loadingAction,"formItems":_vm.formItems},on:{"getActionBtn":_vm.addRecord}}):_vm._e(),(!(_vm.fullDataAudit && _vm.fullDataAudit.length) && !_vm.loading)?_c('ErrorAuditsNo'):_vm._e(),(
      _vm.fullDataAudit &&
      _vm.fullDataAudit.length &&
      this.$route.query.type &&
      !(
        _vm.fullDataAudit[0][this.$route.query.type] &&
        _vm.fullDataAudit[0][this.$route.query.type].length
      ) &&
      !_vm.loading
    )?_c('ErrorRecordsNo'):_vm._e(),(_vm.actionItems.tables.length)?_c('BottomMulti',{attrs:{"loadingBottom":_vm.loadingBottom,"active":_vm.activeActionItems,"dataItems":_vm.actionItems,"collapseGroup":_vm.collapseGroup,"showSave":['admin', 'auditor'].includes(_vm.$auth.roles())},on:{"initActive":_vm.initActive,"apply":_vm.applyAddItem}}):_vm._e(),_c('v-row',{staticClass:"px-5",attrs:{"no-gutters":""}},[(
        this.$route.query.action &&
        this.$route.query.action === 'report' &&
        this.$route.query.id
      )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"red lighten-2","icon":"","small":""},on:{"click":function($event){return _vm.$refs.html2Pdf.generatePdf()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-file-pdf-outline")])],1)]}}],null,false,842446172)},[_c('span',[_vm._v("Скачать в PDF")])]):_vm._e(),(_vm.progressPDF > 0 && _vm.progressPDF < 100)?_c('v-progress-linear',{attrs:{"value":_vm.progressPDF}}):_vm._e()],1),(
      this.$route.query.action &&
      this.$route.query.action === 'report' &&
      this.$route.query.id &&
      !_vm.loading
    )?_c('Report',{staticClass:"mb-6",attrs:{"sparkline":true,"loadFinding":_vm.loadFinding,"filterDynamic":_vm.filterDynamic,"filterDate":true,"title":'Динамика качества',"units":_vm.reportDynamic},on:{"find":_vm.find}}):_vm._e(),_c('v-divider'),(
      this.$route.query.action &&
      this.$route.query.action === 'report' &&
      this.$route.query.id
    )?_c('Report',{attrs:{"units":_vm.report}}):_vm._e(),(
      this.$route.query.action &&
      this.$route.query.action === 'report' &&
      this.$route.query.id
    )?_c('Report',{attrs:{"units":_vm.reportNew},on:{"exportOut":_vm.exportOut}}):_vm._e(),(
      this.$route.query.action &&
      this.$route.query.action === 'report' &&
      this.$route.query.id
    )?_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":true,"preview-modal":true,"paginate-elements-by-height":1400,"filename":"hee hee","pdf-quality":2,"manual-pagination":false,"pdf-content-width":"100%","pdf-format":"a3","pdf-orientation":"landscape"},on:{"progress":function($event){return _vm.onProgress($event)},"hasStartedGeneration":function($event){return _vm.hasStartedGeneration()},"hasGenerated":function($event){return _vm.hasGenerated($event)}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[(
          this.$route.query.action &&
          this.$route.query.action === 'report' &&
          this.$route.query.id &&
          !_vm.loading
        )?_c('Report',{staticClass:"mb-6",attrs:{"sparkline":true,"loadFinding":_vm.loadFinding,"filterDynamic":_vm.filterDynamic,"filterDate":true,"title":'Диамика качества',"units":_vm.reportDynamic},on:{"find":_vm.find}}):_vm._e(),_c('v-divider'),(
          this.$route.query.action &&
          this.$route.query.action === 'report' &&
          this.$route.query.id
        )?_c('Report',{attrs:{"units":_vm.report}}):_vm._e(),(
          this.$route.query.action &&
          this.$route.query.action === 'report' &&
          this.$route.query.id
        )?_c('Report',{attrs:{"units":_vm.reportNew},on:{"exportOut":_vm.exportOut}}):_vm._e()],1)]):_vm._e(),(
      this.$route.query.action &&
      this.$route.query.action === 'report' &&
      this.$route.query.id &&
      !_vm.report.length &&
      !_vm.loading
    )?_c('ErrorReportDataNo',{staticClass:"mt-6"}):_vm._e(),(
      this.$route.query.action &&
      this.$route.query.action === 'listOpinions' &&
      this.$route.query.id &&
      !this.listOpinions.items.length &&
      !_vm.loading
    )?_c('ErrorOpinionsNo'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }