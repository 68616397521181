// import { mdiCheckDecagramOutline, mdiImageFilterCenterFocusStrongOutline } from '@mdi/js';
export default {
    loadCatalog: false,
    sectionMoreCatalog: {},
    selectMoreCatalog: {},
    dataTable: [
        {
            name: "units_evaluation",
            title: "Блоки оценок",
            // tooltip: '<b>Связки</b> - это соотношение источника, группы, площадки и воркера на заданный период, для построения финальных отчетов',
            // tooltipIcon: mdiHelpCircleOutline,
            table: [],
            // icon: mdiCheckDecagramOutline,
            headers: [],
            multi: true,
            add: {
                title: "Добавление блока оценки",
                titleEdit: "Редактирование блока оценки",
                name: "add_units_evaluation",
                data: [
                    {
                        type: "text",
                        title: "Название",
                        name: "name",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: true,
                        required: true,
                        block: true,
                    },
                    {
                        type: "textarea",
                        title: "Описание",
                        name: "description",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: false,
                        required: false,
                        block: true,
                    },
                    // {
                    //     type: "colorpicker",
                    //     title: "Цвет",
                    //     name: "color",
                    //     placeholder: "",
                    //     model: '#FEF2CC',
                    //     items: [],
                    //     filter: false,
                    //     required: false,
                    //     block: true,
                    // },
                ]
            }
        },
        {
            name: "evaluations",
            title: "Что оцениваем",
            // tooltip: '<b>Связки</b> - это соотношение источника, группы, площадки и воркера на заданный период, для построения финальных отчетов',
            // tooltipIcon: mdiHelpCircleOutline,
            table: [],
            // icon: mdiCheckDecagramOutline,
            headers: [],
            multi: true,
            multiEdit: true,
            add: {
                title: 'Добавление "Что оцениваем"',
                titleEdit: 'Редактирование "Что оцениваем"',
                titleMultiEdit: 'Мульти - редактирование "Что оцениваем"',
                alert: 'Внимание! Все данные, которые Вы укажите применятся ко всем, выбранным Вами "Что оцениваем"!',
                name: "add_evaluations",
                data: [
                    {
                        type: "text",
                        title: "Название",
                        name: "name",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: true,
                        required: true,
                        block: true,
                    },
                    {
                        type: "textarea",
                        title: "Описание",
                        name: "description",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: false,
                        required: false,
                        block: true,
                    },
                    {
                        type: "select",
                        title: "Объект оценки",
                        name: "id_unit_evaluation",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: true,
                        itemText: 'name',
                        required: true,
                        block: true,
                        multiEditField: true,
                    },
                    {
                        type: "select",
                        title: "Кто оцениывет",
                        name: "id_role",
                        placeholder: "",
                        model: null,
                        multi: true,
                        items: [],
                        filter: true,
                        itemText: 'name',
                        required: true,
                        block: true,
                        multiEditField: true,
                    },
                ]
            }
        },

        {
            name: "remarks",
            title: "Замечания",
            // tooltip: '<b>Связки</b> - это соотношение источника, группы, площадки и воркера на заданный период, для построения финальных отчетов',
            // tooltipIcon: mdiHelpCircleOutline,
            table: [],
            // icon: mdiCheckDecagramOutline,
            headers: [],
            multi: true,
            multiEdit: true,
            add: {
                title: 'Добавление замечания',
                titleEdit: 'Редактирование замечания',
                titleMultiEdit: 'Мульти - редактирование замечаний',
                alert: 'Внимание! Все данные, которые Вы укажите применятся ко всем, выбранным Вами замечаниям!',
                name: "add_remarks",
                data: [
                    {
                        type: "text",
                        title: "Название",
                        name: "name",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: true,
                        required: true,
                        block: false,
                    },
                    {
                        type: "text",
                        title: "Балл",
                        name: "scores",
                        placeholder: "",
                        model: null,
                        minVal: -5,
                        maxVal: 1,
                        items: [],
                        filter: false,
                        required: true,
                        block: false,
                    },
                    // {
                    //     type: "text",
                    //     title: "Вес",
                    //     name: "weight",
                    //     placeholder: "",
                    //     model: null,
                    //     items: [],
                    //     filter: false,
                    //     required: true,
                    //     block: false,
                    // },
                    {
                        type: "select",
                        title: "Что оцениваем",
                        name: "id_evaluation",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: true,
                        itemText: 'name',
                        required: true,
                        block: false,
                        multiEditField: true,
                    },
                    {
                        type: "select",
                        title: "Тип оценки",
                        name: "id_entities_evaluation",
                        placeholder: "",
                        model: null,
                        items: [],
                        multi: true,
                        filter: true,
                        itemText: 'name',
                        required: true,
                        block: false,
                        multiEditField: true,
                    },
                ]
            }
        },


        {
            name: "types_objects",
            title: "Типы объектов",
            // tooltip: '<b>Связки</b> - это соотношение источника, группы, площадки и воркера на заданный период, для построения финальных отчетов',
            // tooltipIcon: mdiHelpCircleOutline,
            table: [],
            // icon: mdiCheckDecagramOutline,
            headers: [],
            multi: true,
            add: {
                title: "Добавление типа объекта",
                titleEdit: "Редактирование типа объекта",
                name: "add_types_objects",
                data: [
                    {
                        type: "text",
                        title: "Название",
                        name: "name",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: false,
                        required: true,
                        block: false,
                    },
                    {
                        type: "text",
                        title: "Назване на английском",
                        name: "alias",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: false,
                        required: true,
                        block: false,
                    },
                    {
                        type: "textarea",
                        title: "Описание",
                        name: "description",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: false,
                        required: false,
                        block: true,
                    },

                ]
            }
        },
        {
            name: "areas",
            title: "Участки",
            // tooltip: '<b>Связки</b> - это соотношение источника, группы, площадки и воркера на заданный период, для построения финальных отчетов',
            // tooltipIcon: mdiHelpCircleOutline,
            table: [],
            // icon: mdiCheckDecagramOutline,
            headers: [],
            multi: true,
            add: {
                title: "Добавление участка",
                titleEdit: "Редактирование участка",
                name: "add_areas",
                data: [
                    {
                        type: "text",
                        title: "Название",
                        name: "name",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: true,
                        required: true,
                        block: true,
                    },
                    {
                        type: "textarea",
                        title: "Описание",
                        name: "description",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: false,
                        required: false,
                        block: true,
                    },
                ]
            }
        },
        {
            name: "storerooms",
            title: "Кладовые",
            // tooltip: '<b>Связки</b> - это соотношение источника, группы, площадки и воркера на заданный период, для построения финальных отчетов',
            // tooltipIcon: mdiHelpCircleOutline,
            table: [],
            // icon: 'mdi-shield-account-outline',
            headers: [],
            multi: true,
            add: {
                title: "Добавление кладовой",
                titleEdit: "Редактирование кладовой",
                name: "add_storerooms",
                data: [
                    {
                        type: "text",
                        title: "Название",
                        name: "name",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: true,
                        required: true,
                        block: true,
                    },
                    {
                        type: "textarea",
                        title: "Описание",
                        name: "description",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: false,
                        required: false,
                        block: true,
                    },
                ]
            }
        },
        {
            name: "employees",
            title: "Сотрудники",
            // tooltip: '<b>Связки</b> - это соотношение источника, группы, площадки и воркера на заданный период, для построения финальных отчетов',
            // tooltipIcon: mdiHelpCircleOutline,
            table: [],
            // icon: 'mdi-briefcase-account-outline',
            headers: [],
            multi: true,
            add: {
                title: "Добавление сотрудника",
                titleEdit: "Редактирование сотрудника",
                name: "add_employees",
                data: [
                    {
                        type: "text",
                        title: "Фамилия",
                        name: "sername",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: true,
                        required: true,
                        block: true,
                    },
                    {
                        type: "text",
                        title: "Имя",
                        name: "name",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: true,
                        required: true,
                        block: true,
                    },

                    {
                        type: "text",
                        title: "Отчество",
                        name: "secondname",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: true,
                        required: true,
                        block: true,
                    },
                ]
            }
        },
        {
            name: "roles",
            title: "Роли",
            // tooltip: '<b>Связки</b> - это соотношение источника, группы, площадки и воркера на заданный период, для построения финальных отчетов',
            // tooltipIcon: mdiHelpCircleOutline,
            table: [],
            // icon: mdiImageFilterCenterFocusStrongOutline,
            headers: [],
            multi: true,
            add: {
                title: "Добавление роли",
                titleEdit: "Редактирование роли",
                name: "add_roles",
                data: [
                    {
                        type: "text",
                        title: "Название",
                        name: "name",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: false,
                        required: true,
                        block: false,
                    },
                    {
                        type: "text",
                        title: "Назване на английском",
                        name: "alias",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: false,
                        required: true,
                        block: false,
                    },
                    {
                        type: "textarea",
                        title: "Описание",
                        name: "description",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: false,
                        required: false,
                        block: true,
                    },
                ]
            }
        },
        {
            name: "entities_evaluation",
            title: "Типы оценок",
            // tooltip: '<b>Связки</b> - это соотношение источника, группы, площадки и воркера на заданный период, для построения финальных отчетов',
            // tooltipIcon: mdiHelpCircleOutline,
            table: [],
            // icon: mdiImageFilterCenterFocusStrongOutline,
            headers: [],
            multi: true,
            add: {
                title: "Добавление типа оценки",
                titleEdit: "Редактирование типа оценки",
                name: "add_entities_evaluation",
                data: [
                    {
                        type: "text",
                        title: "Название",
                        name: "name",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: true,
                        required: true,
                        block: true,
                    },
                    {
                        type: "textarea",
                        title: "Описание",
                        name: "description",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: false,
                        required: false,
                        block: true,
                    },
                ]
            }
        },

        {
            name: "workspaces",
            title: "Рабочие места",
            // tooltip: '<b>Связки</b> - это соотношение источника, группы, площадки и воркера на заданный период, для построения финальных отчетов',
            // tooltipIcon: mdiHelpCircleOutline,
            table: [],
            // icon: mdiImageFilterCenterFocusStrongOutline,
            headers: [],
            multi: true,
            add: {
                title: "Добавление рабочего места",
                titleEdit: "Редактирование рабочего места",
                name: "add_workspaces",
                data: [
                    {
                        type: "text",
                        title: "Название",
                        name: "name",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: true,
                        required: true,
                        block: true,
                    },
                    {
                        type: "textarea",
                        title: "Описание",
                        name: "description",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: false,
                        required: false,
                        block: true,
                    },
                ]
            }
        },

        {
            name: "surfaces",
            title: "Поверхности",
            // tooltip: '<b>Связки</b> - это соотношение источника, группы, площадки и воркера на заданный период, для построения финальных отчетов',
            // tooltipIcon: mdiHelpCircleOutline,
            table: [],
            // icon: mdiImageFilterCenterFocusStrongOutline,
            headers: [],
            multi: true,
            add: {
                title: "Добавление поверхности",
                titleEdit: "Редактирование поверхности",
                name: "add_surfaces",
                data: [
                    {
                        type: "text",
                        title: "Название",
                        name: "name",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: true,
                        required: true,
                        block: true,
                    },
                    {
                        type: "textarea",
                        title: "Описание",
                        name: "description",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: false,
                        required: false,
                        block: true,
                    },
                ]
            }
        },

        {
            name: "relation_weight_units_evaluation_entities_evaluation",
            title: "Веса типов оценок",
            // tooltip: '<b>Связки</b> - это соотношение источника, группы, площадки и воркера на заданный период, для построения финальных отчетов',
            // tooltipIcon: mdiHelpCircleOutline,
            table: [],
            // icon: mdiImageFilterCenterFocusStrongOutline,
            headers: [],
            multi: true,
            add: {
                title: "Добавление веса типа оценки",
                titleEdit: "Редактирование веса типа оценки",
                name: "add_relation_weight_units_evaluation_entities_evaluation",
                data: [
                    {
                        type: "select",
                        title: "Блок оценки",
                        name: "id_unit_evaluation",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: true,
                        required: true,
                        itemText: 'name',

                    },
                    {
                        type: "select",
                        title: "Тип оценки",
                        name: "id_entitie_evaluation",
                        placeholder: "",
                        model: null,
                        items: [],
                        filter: true,
                        required: true,
                        itemText: 'name',

                    },
                    {
                        type: "text",
                        title: "Вес",
                        name: "weight",
                        placeholder: "",
                        minVal: 0,
                        maxVal: 1,

                        model: null,
                        items: [],
                        filter: false,
                        required: true,
                        block: true,
                    },
                ]
            }
        }

    ]
}