<template>
  <div class="px-5 mt-2">
    <h2
      v-if="title"
      style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
      class="black--text"
    >
      {{ title }}
    </h2>
    <v-row no-gutters>
      <v-col>
        <SearchBlock
          :loadFinding="loadFinding"
          @find="$emit('find')"
          style="z-index: 10"
          :loadDataFilter="loadDataFilter"
          v-if="filterDate"
          :filter="filterDynamic"
        />
      </v-col>
      <v-progress-linear
        v-if="loadFinding"
        color="primary"
        indeterminate
        reverse
      ></v-progress-linear>
    </v-row>

    <v-text-field
      v-if="units.length && units.find((e) => e.search)"
      v-model="search"
      class="mb-3"
      dense
      append-icon="mdi-magnify"
      label="Поиск по оценкам"
      single-line
      hide-details
    ></v-text-field>
    <h2
      v-if="!units.length && filterDate"
      class="grey--text text--lighten-1 mt-3 text-center"
    >
      В эти даты аудитов нет
    </h2>
    <v-row :class="{ 'mt-0': filterDate }">
      <!-- <div class="d-flex flex-wrap"> -->

      <v-col
        cols="12"
        :sm="index === units.length - 1 && units.length % 2 !== 0 ? 12 : 6"
        v-for="(unit, index) in units"
        :key="unit.name"
      >
        <v-card
          class="mx-auto"
          min-width="100%"
          elevation="0"
          style="border: 1px solid gray"
        >
          <v-card-text>
            <v-row class="mb-3" no-gutters align="center">
              <h2
                style="
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                class="black--text"
              >
                {{ unit.title }}
              </h2>

              <v-tooltip bottom v-if="unit.chart">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2"
                    color="blue lighten-2"
                    @click="unit.tab = 'table'"
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon size="22">mdi-table</v-icon></v-btn
                  >
                </template>
                <span>Показать таблицу</span>
              </v-tooltip>

              <v-tooltip bottom v-if="unit.chart">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2"
                    color="blue lighten-2"
                    @click="
                      () => {
                        unit.tab = 'chart';
                      }
                    "
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon size="22">mdi-chart-line</v-icon></v-btn
                  >
                </template>
                <span>Показать график</span>
              </v-tooltip>

              <v-tooltip bottom v-if="unit.chart">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2"
                    color="blue lighten-2"
                    @click="
                      () => {
                        unit.tab = 'chart-bar';
                      }
                    "
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon size="22">mdi-chart-bar</v-icon></v-btn
                  >
                </template>
                <span>Показать диаграмму</span>
              </v-tooltip>

              <v-tooltip bottom v-if="unit.exportPDF">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2"
                    color="red lighten-2"
                    @click="exportOut('PDF', unit)"
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon size="22">mdi-file-pdf-outline</v-icon></v-btn
                  >
                </template>
                <span>Скачать в PDF</span>
              </v-tooltip>

              <v-tooltip bottom v-if="unit.exportExcel">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="teal"
                    @click="exportOut('Excel', unit)"
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon size="22">mdi-file-excel-outline</v-icon></v-btn
                  >
                </template>
                <span>Скачать в Excel</span>
              </v-tooltip>
            </v-row>

            <v-data-table
              v-if="unit.tab === 'table'"
              no-data-text="Данные не найдены"
              no-results-text="Данные не найдены"
              :search="unit.search ? search : ''"
              mobile-breakpoint="0"
              dense
              :hide-default-footer="unit.name !== 'full_records'"
              :hide-default-header="!unit.header"
              :headers="
                unit.fields.map((m) => {
                  return {
                    value: m.name,
                    text: m.text ? m.text : m.name,
                    filterable: true,
                    sortable: m.sortable,
                    align: m.align,
                    cellClass: m.class,
                    class: m.headerClass,
                    divider: m.divider,
                  };
                })
              "
              :items="unit.items"
            >
              <template v-if="sparkline" v-slot:[`item.sparkline`]="{ item }">
                <v-sparkline
                  style="width: 100px"
                  :line-width="4"
                  :smooth="3"
                  :value="
                    Object.values(item).filter((f) => typeof f === 'number')
                  "
                  auto-draw
                ></v-sparkline>
              </template>
            </v-data-table>

            <line-chart
              style="position: relative; max-height: 160px"
              v-if="unit.chart && unit.tab === 'chart'"
              :chartdata="{
                labels: unit.items.map((_m) => _m.unit),
                datasets: [
                  {
                    label: 'Блоки оценок',
                    fill: true,
                    borderColor: '#90CAF9',
                    backgroundColor: '#E3F2FD',
                    order: 1,
                    borderWidth: 2,
                    data: unit.items.map((_m) => _m.grade),
                  },
                ],
              }"
              :options="{
                responsive: true,
                maintainAspectRatio: false,
                height: 100,
                layout: {
                  padding: 0,
                },
                legend: {
                  display: false,
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                  xAxes: [
                    {
                      display: false,
                      ticks: {
                        maxTicksLimit: 3, // максимальное количество значений (и рёбер сетки) по оси x
                      },
                    },
                  ],
                },
              }"
            ></line-chart>

            <horizontal-bar-chart
              style="position: relative; max-height: 160px"
              v-if="unit.chart && unit.tab === 'chart-bar'"
              :chartdata="{
                labels: unit.items.map((_m) => _m.unit),
                datasets: [
                  {
                    label: 'Блоки оценок',
                    fill: false,

                    order: 1,
                    borderWidth: 2,
                    backgroundColor: unit.items.map((_m) =>
                      _m.grade > 0 ? '#B2DFDB' : '#FFCDD2'
                    ),
                    borderColor: unit.items.map((_m) =>
                      _m.grade > 0 ? '#80CBC4' : '#EF9A9A'
                    ),
                    data: unit.items.map((_m) => _m.grade),
                  },
                ],
              }"
              :options="{
                responsive: true,
                maintainAspectRatio: false,
                height: 100,
                layout: {
                  padding: 0,
                },
                legend: {
                  display: false,
                },
                scales: {
                  xAxes: [
                    {
                      display: false,
                      ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 3, // максимальное количество значений (и рёбер сетки) по оси x
                      },
                    },
                  ],
                },
              }"
            ></horizontal-bar-chart>

            <!-- <v-list>
              <v-list-item
                style="min-height: 20px"
                class="px-0"
                v-for="_i in unit.items"
                :key="_i.unit"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row no-gutters justify="space-between">
                      <v-col cols="auto">
                        {{ _i.unit }}
                      </v-col>
                      <v-spacer class="sub_line"></v-spacer>
                      <v-col cols="auto"> {{ _i.scores }} </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list> -->
          </v-card-text>
        </v-card>
      </v-col>
      <!-- </div> -->
    </v-row>
  </div>
</template>
<script>
import ParamReprt from "./paramsReport";
import LineChart from "./../../views/charts/LineChart.vue";
import HorizontalBarChart from "../../views/charts/HorizontalBarChart.vue";
import SearchBlock from "../../units/search/search.vue";
export default {
  props: [
    "units",
    "title",
    "filterDate",
    "loadFinding",
    "sparkline",
    "filterDynamic",
  ],
  components: {
    LineChart,
    HorizontalBarChart,
    SearchBlock,
  },
  data() {
    return { ...ParamReprt };
  },
  methods: {
    fillData(v) {
      console.log(v);

      this.datacollection = {
        labels: ["Качество", "Стандарт"],
        datasets: [
          {
            label: "Профит по трекеру",
            fill: false,
            borderColor: "rgba(54, 162, 235, 1)",
            order: 1,
            borderWidth: 2,
            data: [10, 20, 14, 5],
          },
        ],
      };
    },

    exportOut(type, unit) {
      this.$emit("exportOut", type, unit);
    },
  },
};
</script>
<style scoped>
::v-deep .sub_line {
  border-bottom: 1px solid gray;
}
::v-deep .v-list-item__content {
  align-items: center;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  overflow: hidden;
  padding: 5px 0;
}
::v-deep .cell {
  padding-left: 0px !important;
  padding-right: 0px !important;
  white-space: pre-wrap;
}
</style>