var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{staticClass:"new-class-bottom",attrs:{"fixed":"","bottom":"","light":"","floating":"","width":"456"},model:{value:(_vm.activeModel),callback:function ($$v) {_vm.activeModel=$$v},expression:"activeModel"}},[_c('div',{staticStyle:{"position":"relative"}},[_c('v-btn',{staticClass:"close-btn-bottom",attrs:{"icon":""},on:{"click":function($event){_vm.activeModel = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('div',{staticClass:"pa-3 white",staticStyle:{"position":"sticky","top":"0","z-index":"1","border-bottom":"1px solid #ededed !important"}},[_c('v-row',{staticStyle:{"position":"relative"},attrs:{"no-gutters":"","justify":"space-between","align":"center"}},[(_vm.loadingBottom)?_c('v-skeleton-loader',{attrs:{"min-width":"200","type":"heading"}}):_vm._e(),_c('v-col',{staticClass:"header-bottom"},[(!_vm.loadingBottom)?_c('h3',{staticStyle:{"overflow":"hidden"}},[_vm._v(" "+_vm._s(_vm.dataItems.label)+" ")]):_vm._e()]),_c('v-col',{attrs:{"cols":"auto"}},[(!_vm.loadingBottom && _vm.showSave)?_c('v-btn',{staticClass:"new-btn",attrs:{"color":"success","text":""},on:{"click":function($event){_vm.$emit(
                  'apply',
                  _vm.modelList.map(function (m) { return m.id; }),
                  _vm.dataItems.items
                )}}},[_vm._v("Сохранить")]):_vm._e()],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[(!_vm.loadingBottom)?_c('v-text-field',{staticClass:"mt-2",attrs:{"hide-details":"","outlined":"","dense":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1)],1),(_vm.loadingBottom)?_c('v-skeleton-loader',{staticClass:"px-3",attrs:{"min-width":"200","type":"text@3"}}):_vm._e(),(!_vm.loadingBottom)?_c('v-data-table',{attrs:{"group-by":[_vm.groping],"no-data-text":"Данных нет","no-results-text":"Данных нет","selectable-key":"id","items-per-page":1000,"search":_vm.search,"items":_vm.dataItems.items,"headers":[
          {
            value: 'name',
            text: 'name',
            filterable: true,
            align: 'start',
            cellClass: 'textOverflow',
          } ],"item-class":_vm.itemClass,"hide-default-footer":"","hide-default-header":"","mobile-breakpoint":"0"},on:{"click:row":_vm.selectRow},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
        var items = ref.items;
        var isOpen = ref.isOpen;
        var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"2"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? "mdi-minus" : "mdi-plus")+" ")]),_vm._v(" "+_vm._s(items[0][_vm.groping])+" ")],1)]}},(_vm.dataItems.text)?{key:"item.name",fn:function(props){return [_c('div',[_c('v-textarea',{attrs:{"auto-grow":""},model:{value:(props.item.name),callback:function ($$v) {_vm.$set(props.item, "name", $$v)},expression:"props.item.name"}})],1)]}}:null],null,true),model:{value:(_vm.modelList),callback:function ($$v) {_vm.modelList=$$v},expression:"modelList"}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }