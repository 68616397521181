export default {
    hiddenFilter: false,
    findItems: [
        {
            type: 'text',
            name: 'name',
            title: 'Имя',
            model: null,
            filter: true,
        },
        {
            type: 'select',
            name: 'id_status_object',
            title: 'Статус объекта',
            items: [],
            itemText: 'name',
            itenValue: 'id',
            model: null,
            filter: true,
        },
        // {
        //     type: 'select',
        //     name: 'id_manager',
        //     title: 'Менеджер',
        //     items: [],
        //     itemText: (v) => v.sername + ' ' + v.name,
        //     itenValue: 'id',
        //     model: null,
        //     filter: true,
        // }
    ],
    list: {
        color: "blue lighten-3",
        // btn: "Завершить аудит",
        dense: true,
        items: [

        ]
    }
}