export default {
    loadCatalog: false,
    dataTable: [{

        name: "userauth",
        title: "Пользователи",
        // tooltip: '<b>Связки</b> - это соотношение источника, группы, площадки и воркера на заданный период, для построения финальных отчетов',
        // tooltipIcon: mdiHelpCircleOutline,
        table: [],
        // icon: mdiCheckDecagramOutline,
        headers: [],
        multi: true,
        add: {
            title: "Добавление пользователя",
            titleEdit: "Редактирование пользователя",
            name: "add_users",
            data: [
                {
                    type: "text",
                    title: "Логин",
                    name: "login",
                    placeholder: "",
                    model: null,
                    items: [],
                    filter: true,
                    required: true,
                    block: false,
                },
                {
                    type: "text",
                    title: "Имя",
                    name: "name",
                    placeholder: "",
                    model: null,
                    items: [],
                    filter: true,
                    required: true,
                    block: false,
                },
                {
                    type: "text",
                    title: "Фамилия",
                    name: "sername",
                    placeholder: "",
                    model: null,
                    items: [],
                    filter: false,
                    required: true,
                    block: false,
                },
                {
                    type: "text",
                    title: "Отчество",
                    name: "secondname",
                    placeholder: "",
                    model: null,
                    items: [],
                    filter: false,
                    required: false,
                    block: false,
                },
                {
                    type: "text",
                    title: "Контакты",
                    name: "contacts",
                    placeholder: "",
                    model: null,
                    items: [],
                    filter: false,
                    required: false,
                    block: false,
                },
                {
                    type: "select",
                    title: "Роль",
                    name: "id_role",
                    placeholder: "",
                    model: null,
                    items: [],
                    filter: true,
                    required: true,
                    block: false,
                    itemText: 'name',
                },
                {
                    type: "select",
                    title: "Доступные объекты",
                    name: "id_object",
                    placeholder: "",
                    model: null,
                    multi: true,
                    show: { field: 'id_role', value: 'name', model: ['Менеджер', 'Клиент', 'Внешний аудитор'] },
                    items: [],
                    filter: false,
                    required: false,
                    block: false,
                    itemText: 'name',
                },
            ]
        }

    }]
}