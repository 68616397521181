<template>
  <div style="position: relative" ref="startPage">
    <Tree
      v-if="(fullDataAudit && fullDataAudit.length) || loading"
      class="stickyTree"
      @getBtnClick="getBtnClick"
      @getLongBtnClick="getLongBtnClick"
      :tree="tree"
      :loading="loading"
    />
    <ActionList
      :loading="loading"
      :loadingAction="false"
      @getActionBtnActionList="getActionBtnActionList"
      v-if="
        this.$route.query.action &&
        this.$route.query.action === 'edit' &&
        fullDataAudit &&
        fullDataAudit.length
      "
      @getClickListItem="actionAudit"
      :list="list"
    />
    <ActionList
      :loading="loading"
      :loadingAction="false"
      v-if="
        (this.$route.query.action &&
          this.$route.query.action === 'addRecord' &&
          this.$route.query.idUnitsEvaluation &&
          !this.$route.query.idEvaluation) ||
        (this.$route.query.action &&
          this.$route.query.action === 'addRecord' &&
          !this.$route.query.idUnitsEvaluation &&
          !this.$route.query.idEvaluation)
      "
      @getClickListItem="getClickListItemUnitsEvaluation"
      :list="listUnitsEvaluation"
    />

    <!-- Типы Записей -->
    <ActionList
      :loading="loading"
      :loadingAction="false"
      v-if="
        this.$route.query.action &&
        this.$route.query.action === 'listRecords' &&
        !this.$route.query.type
      "
      @getClickListItem="actionSectionRecord"
      :list="listSectionRecords"
    />

    <!-- Записе -->
    <ActionList
      :loading="loading"
      :loadingAction="loadingAction"
      v-if="
        this.$route.query.action &&
        this.$route.query.action === 'listRecords' &&
        this.$route.query.type
      "
      :loadAddRaw="loadAddRaw"
      @getClickListItem="choiseRecord"
      @closeDialogRemove="closeDialogRemove"
      @remove="removeItem"
      :dialogRemove="dialogRemove"
      @openDialogRemove="openDialogRemove"
      :list="listRecords"
      :showRemove="['admin', 'auditor'].includes($auth.roles())"
    />

    <!-- Мнения аудиотора -->
    <ActionList
      :loading="loading"
      :loadingAction="loadingAction"
      v-if="
        this.$route.query.action && this.$route.query.action === 'listOpinions'
      "
      @getClickListItem="choiseOpinion"
      :list="listOpinions"
    />

    <!-- Дата открытия аудита -->
    <ActionList
      :loading="loading"
      :loadingAction="loadingAction"
      v-if="this.$route.query.action && this.$route.query.action === 'dateOpen'"
      @getClickListItem="choiseDateOpen"
      :list="dateOpen"
    />

    <Form
      :loading="loading"
      :loadingAction="loadingAction"
      @getActionBtn="addRecord"
      v-if="
        (formItems.items.length &&
          this.$route.query.action &&
          this.$route.query.action === 'addRecord' &&
          this.$route.query.idUnitsEvaluation &&
          this.$route.query.idEvaluation) ||
        (formItems.items.length &&
          this.$route.query.action &&
          this.$route.query.action === 'addOpinionAudit')
      "
      :formItems="formItems"
    />

    <ErrorAuditsNo
      v-if="!(fullDataAudit && fullDataAudit.length) && !loading"
    />

    <ErrorRecordsNo
      v-if="
        fullDataAudit &&
        fullDataAudit.length &&
        this.$route.query.type &&
        !(
          fullDataAudit[0][this.$route.query.type] &&
          fullDataAudit[0][this.$route.query.type].length
        ) &&
        !loading
      "
    />

    <BottomMulti
      v-if="actionItems.tables.length"
      :loadingBottom="loadingBottom"
      @initActive="initActive"
      :active="activeActionItems"
      :dataItems="actionItems"
      @apply="applyAddItem"
      :collapseGroup="collapseGroup"
      :showSave="['admin', 'auditor'].includes($auth.roles())"
    />

    <v-row no-gutters class="px-5">
      <v-tooltip
        bottom
        v-if="
          this.$route.query.action &&
          this.$route.query.action === 'report' &&
          this.$route.query.id
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            color="red lighten-2"
            @click="$refs.html2Pdf.generatePdf()"
            icon
            small
            v-bind="attrs"
            v-on="on"
            ><v-icon size="22">mdi-file-pdf-outline</v-icon></v-btn
          >
        </template>
        <span>Скачать в PDF</span>
      </v-tooltip>
      <v-progress-linear
        v-if="progressPDF > 0 && progressPDF < 100"
        :value="progressPDF"
      ></v-progress-linear>
    </v-row>

    <!-- Динамика качества -->
    <Report
      class="mb-6"
      :sparkline="true"
      :loadFinding="loadFinding"
      @find="find"
      :filterDynamic="filterDynamic"
      :filterDate="true"
      :title="'Динамика качества'"
      v-if="
        this.$route.query.action &&
        this.$route.query.action === 'report' &&
        this.$route.query.id &&
        !loading
      "
      :units="reportDynamic"
    />

    <v-divider></v-divider>

    <!-- Оценки и итоговые баллы -->
    <Report
      v-if="
        this.$route.query.action &&
        this.$route.query.action === 'report' &&
        this.$route.query.id
      "
      :units="report"
    />

    <!-- Список записей -->
    <Report
      @exportOut="exportOut"
      v-if="
        this.$route.query.action &&
        this.$route.query.action === 'report' &&
        this.$route.query.id
      "
      :units="reportNew"
    />

    <vue-html2pdf
      v-if="
        this.$route.query.action &&
        this.$route.query.action === 'report' &&
        this.$route.query.id
      "
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="hee hee"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-content-width="100%"
      pdf-format="a3"
      pdf-orientation="landscape"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <!-- Динамика качества -->
        <Report
          class="mb-6"
          :sparkline="true"
          :loadFinding="loadFinding"
          @find="find"
          :filterDynamic="filterDynamic"
          :filterDate="true"
          :title="'Диамика качества'"
          v-if="
            this.$route.query.action &&
            this.$route.query.action === 'report' &&
            this.$route.query.id &&
            !loading
          "
          :units="reportDynamic"
        />

        <v-divider></v-divider>

        <!-- Оценки и итоговые баллы -->
        <Report
          v-if="
            this.$route.query.action &&
            this.$route.query.action === 'report' &&
            this.$route.query.id
          "
          :units="report"
        />

        <!-- Список записей -->
        <Report
          @exportOut="exportOut"
          v-if="
            this.$route.query.action &&
            this.$route.query.action === 'report' &&
            this.$route.query.id
          "
          :units="reportNew"
        />
      </section>
    </vue-html2pdf>

    <ErrorReportDataNo
      class="mt-6"
      v-if="
        this.$route.query.action &&
        this.$route.query.action === 'report' &&
        this.$route.query.id &&
        !report.length &&
        !loading
      "
    />

    <ErrorOpinionsNo
      v-if="
        this.$route.query.action &&
        this.$route.query.action === 'listOpinions' &&
        this.$route.query.id &&
        !this.listOpinions.items.length &&
        !loading
      "
    />

    <!-- <vue-context ref="menu">
      <ul>
        <li @click="onClick($event.target.innerText)">Option 1</li>
        <li @click="onClick($event.target.innerText)">Option 2</li>
      </ul>
    </vue-context> -->
  </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
import Helper from "./../../../plugins/helper";
import ParamsAudit from "./paramsAudit";
import Tree from "./../../units/tree/tree.vue";
import ActionList from "./../../units/actionList/actionList.vue";
import Form from "./../../units/form/form.vue";
import ParamsCommon from "./../../paramsCommon";
import catalogs from "./../../../plugins/catalogs";
import ErrorAuditsNo from "./../../views/errors/notAudit.vue";
import ErrorRecordsNo from "./../../views/errors/notRecords.vue";
import ErrorOpinionsNo from "./../../views/errors/notOpinions.vue";
import ErrorReportDataNo from "./../../views/errors/notReportData.vue";
import BottomMulti from "./../../units/bottomMulti/bottomMulti.vue";

import Report from "./../../units/report/report.vue";

// import { VueContext } from "vue-context";

export default {
  components: {
    Tree,
    ActionList,
    Form,
    ErrorAuditsNo,
    ErrorRecordsNo,
    BottomMulti,
    Report,
    ErrorReportDataNo,
    ErrorOpinionsNo,
    VueHtml2pdf,
  },
  data() {
    return { ...ParamsAudit, ...ParamsCommon };
  },
  async mounted() {
    await this.getDataFromDB();
    this.initData();
    if (
      this.$route.query.action &&
      this.$route.query.action === "report" &&
      this.$route.query.id &&
      !this.loading
    )
      await this.find();
  },

  watch: {
    async $route() {
      this.initData();

      if (
        this.$route.query.action &&
        this.$route.query.action === "report" &&
        this.$route.query.id &&
        !this.loading
      )
        await this.find();
    },
  },

  methods: {
    ...Helper,
    ...catalogs,

    onProgress(e) {
      console.log("EVENT !!", e);
      this.progressPDF = e;
    },
    prepareDynamicReport(initObj) {
      return this.groupBy(
        initObj.reduce((a, s) => a.concat(s.data), []),
        (g) => g.name
      )
        .reduce((acc, sum) => {
          sum = sum.reduce((acc1, sum1) => {
            let res = {};
            Object.keys(sum1).forEach((o) => {
              if (o === "name") res[o] = sum1[o];
              else {
                if (o === "audit_date")
                  res[
                    new Date(sum1[o]).toLocaleString("ru-RU").split(", ")[0]
                  ] = sum1["grade"];
              }
            });
            return { ...acc1, ...res };
          }, {});
          return acc.concat(sum);
        }, [])
        .filter(
          (f) =>
            !Object.values(f)
              .filter((_f) => typeof _f === "number")
              .every((e) => e === 0)
        );
    },

    async find() {
      const v = this.filterDynamic;
      console.log("find", v);
      if (this.fullDataAudit && this.fullDataAudit.length) {
        this.loadFinding = true;
        const itemsDynamicReport = (
          await this.findCatalog("dynamic_report", {
            dates: v[0].model,
            id_object: this.fullDataAudit[0].id_object,
          })
        ).data.items;
        // console.log(
        //   "itemsDynamicReport",
        //   this.prepareDynamicReport(itemsDynamicReport[0].data)
        // );

        this.reportDynamic = itemsDynamicReport.map((m) => {
          let obj = this.prepareDynamicReport(m.data);
          if (m.type !== "unit") {
            obj = obj.filter((f) =>
              ["client"].includes(this.$auth.roles())
                ? f.name === "полная_оценка"
                : f
            );
          }
          if (m.type === "unit") {
            obj = obj.filter((f) =>
              ["client"].includes(this.$auth.roles())
                ? this.permissClientUnit.includes(f.name.toLowerCase())
                : f
            );
          }
          return {
            tab: "table",
            chart: false,
            title: m.type === "unit" ? "Оценки" : "Итоговые баллы",
            search: false,
            header: true,
            fields:
              obj && obj.length
                ? Object.keys(obj[0])
                    .map((m) => {
                      return {
                        name: m,
                        text: m === "name" ? " " : m,
                        align: "start",
                        headerClass:
                          this.fullDataAudit &&
                          this.fullDataAudit.length &&
                          new Date(this.fullDataAudit[0].clos_date)
                            .toLocaleString("ru-RU")
                            .split(", ")[0] === m
                            ? "red--text text--lighten-2 font-weight-bold"
                            : "",
                        class:
                          this.fullDataAudit &&
                          this.fullDataAudit.length &&
                          new Date(this.fullDataAudit[0].clos_date)
                            .toLocaleString("ru-RU")
                            .split(", ")[0] === m
                            ? "red--text text--lighten-2 font-weight-bold"
                            : "",
                        sortable: false,
                      };
                    })
                    .concat([
                      {
                        name: "sparkline",
                        text: " ",
                        align: "center",
                        sortable: false,
                      },
                    ])
                : [],
            items: obj,
          };
        });

        this.loadFinding = false;
      }
      // reportDynamic =
    },

    async removeItem(v) {
      console.log(v);
      this.loadAddRaw = true;
      await this.removeFromCatalog(this.$route.query.type, v.id);
      this.tree = {};
      await this.getDataFromDB();
      this.initData();
      this.loadAddRaw = false;
      this.dialogRemove = false;
      this.removeRaw = !this.removeRaw;
    },
    /**
     * Экспорт PDF / Excel
     */
    async exportOut(type, v) {
      const data = {
        id: this.$route.query.id,
        id_role: this.$auth.user().id_role,
        id_user: ["manager", "client", "external_auditor"].includes(
          this.$auth.roles()
        )
          ? this.$auth.user().id
          : null,
      };
      switch (type) {
        case "PDF":
          console.log(v);
          await this.exportPDF("audits", data, v.name);
          break;
        case "Excel":
          console.log(v);
          await this.exportExcel("audits", data, v.name, "Отчет по записям");
          break;

        default:
          break;
      }
    },
    /**
     * ПРименение сохранения
     */
    async applyAddItem(v) {
      if (this.action === "listRecords" && this.type) {
        this.tree = {};
        console.log(v);
        const data = v.tables.reduce((acc, sum) => {
          return Object.assign(acc, {
            [sum.name]:
              sum.text || sum.date ? sum.items[0].name : sum.model[0].id,
          });
        }, {});
        this.loadingAction = true;
        await this.updateToCatalog(v.type, data, v.id);
        this.activeActionItems = false;
        this.loadingAction = false;
        await this.getDataFromDB();
        this.initData();
        console.log(data);
      }
      if (this.action === "listOpinions") {
        const data = v.tables.reduce((acc, sum) => {
          return Object.assign(acc, {
            [sum.name]: sum.text ? sum.items[0].name : sum.model[0].id,
          });
        }, {});

        this.loadingAction = true;
        await this.updateToCatalog("opinions_auditor", data, v.id);
        this.activeActionItems = false;
        this.loadingAction = false;
        await this.getDataFromDB();
        this.initData();
        console.log(data, v);
      }

      //Изменение даты
      if (this.action === "dateOpen") {
        const data = v.tables.reduce((acc, sum) => {
          return Object.assign(acc, {
            [sum.name]: sum.date ? sum.items[0].name : sum.model[0].id,
          });
        }, {});

        const base =
          this.fullDataAudit && this.fullDataAudit.length
            ? this.fullDataAudit[0]
            : {};
        if (!base.closed) {
          data.close_date = this.getDatePlusDays(new Date(data.open_date), 30);
        } else delete data.close_date;

        this.loadingAction = true;
        await this.updateToCatalog("audits", data, this.id);
        this.activeActionItems = false;
        this.loadingAction = false;
        await this.getDataFromDB();
        this.initData();
        console.log(data, v);
      }
    },

    initActive(v) {
      this.activeActionItems = v;
    },

    async choiseOpinion(v) {
      this.activeActionItems = true;
      this.loadingBottom = true;

      this.actionItems.preLabel = null;
      this.actionItems.label = `Мнение аудитора`;
      this.actionItems.subLabel = null;
      this.actionItems.id = v.id;
      this.actionItems.type = null;

      this.actionItems.tables = [
        {
          name: "opinion",
          grouping: "grouping",
          text: true,
          model: [],

          items: [
            {
              id: 0,
              name: v.opinion,
              grouping: "Мнение аудитора",
            },
          ],
        },
      ];

      this.loadingBottom = false;
      console.log(v);
    },

    async choiseDateOpen(v) {
      this.activeActionItems = true;
      this.loadingBottom = true;

      this.actionItems.preLabel = null;
      this.actionItems.label = `Дата открытия`;
      this.actionItems.subLabel = null;
      this.actionItems.id = v.id;
      this.actionItems.type = null;

      this.actionItems.tables = [
        {
          name: "open_date",
          grouping: "grouping",
          date: true,
          model: [],

          items: [
            {
              id: 0,
              name: v.source,
              grouping: "Дата открытия",
            },
          ],
        },
      ];

      this.loadingBottom = false;
      console.log(v);
    },

    async choiseRecord(v) {
      // actionItems
      console.log("v", v);
      const generateGRoping = {
        id_remark: {
          name: "Замечание",
          findGetSubCatalog: {
            name: null,
            id_evaluation: Number(v.id_evaluation),
            id_entitie_evaluationf: null,
          },
        },
        id_employee: {
          name: "Сотрдуник",
          findGetSubCatalog: {
            name: null,
            sername: null,
            secondname: null,
            id_audit: this.id,
          },
        },
        id_area: {
          name: "Участок",
          findGetSubCatalog: {
            name: null,
            id_audit: this.id,
          },
        },
        details: {
          name: "Подробности",
        },
        create_date: {
          name: "Дата",
        },
        id_surface: {
          name: "Поверхность",
          findGetSubCatalog: {
            name: null,
            id_audit: this.id,
          },
        },
        id_storeroom: {
          name: "Кладовая",
          findGetSubCatalog: {
            name: null,
            id_audit: this.id,
          },
        },
      };
      this.activeActionItems = true;
      this.loadingBottom = true;

      this.actionItems.preLabel = v.scores;
      this.actionItems.label = `${v.remark_name}`;
      this.actionItems.subLabel = v.evaluation;
      this.actionItems.id = v.id;
      this.actionItems.type = this.type;

      const filterItems = Object.keys(v).filter((_f) =>
        [
          "id_remark",
          "id_employee",
          "id_area",
          "id_surface",
          "id_storeroom",
          "details",
          "create_date",
        ].includes(_f)
      );
      console.log(filterItems);
      this.actionItems.tables = await Promise.all(
        filterItems.map(async (m) => {
          return {
            name: m,
            grouping: "grouping",
            text: m === "details" ? true : false,
            date: m === "create_date" ? true : false,
            get model() {
              return ["details", "create_date"].includes(m)
                ? []
                : this.items.filter((_i) => _i.id === v[m]);
            },

            items: ["details", "create_date"].includes(m)
              ? [
                  {
                    id: 0,
                    name: v[m],
                    grouping: generateGRoping[m].name,
                  },
                ]
              : (
                  await this.findCatalog(
                    this.prepareSubCatalogsNAme(m),
                    generateGRoping[m].findGetSubCatalog
                  )
                ).data.items.map((_m) => {
                  return {
                    ..._m,
                    name:
                      m === "id_employee"
                        ? `${_m.name} ${_m.sername} ${_m.secondname}`
                        : _m.name,
                    grouping: generateGRoping[m].name,
                  };
                }),
          };
        })
      );

      console.log(this.actionItems.tables);
      this.loadingBottom = false;
      setTimeout(() => {
        this.collapseGroup = true;
      }, 1);

      // this.actionItems.tables = [
      //   {
      //     name: "one",
      //     grouping: "grouping",
      //     model: [],
      //     items: [
      //       {
      //         id: 1,
      //         name: "1",
      //         grouping: "Сотрдуник",
      //       },
      //       {
      //         id: 2,
      //         name: "2",
      //         grouping: "Сотрдуник",
      //       },
      //     ],
      //   },
      //   {
      //     name: "two_",
      //     grouping: "grouping",
      //     model: [],
      //     items: [
      //       {
      //         id: 1,
      //         name: "3",
      //         grouping: "Замечание",
      //       },
      //       {
      //         id: 2,
      //         name: "4",
      //         grouping: "Замечание",
      //       },
      //     ],
      //   },
      // ];
    },
    /**
     * Выбор типа записей
     */
    actionSectionRecord(v) {
      this.$router.push({
        path: this.$router.currentRoute.fullPath + `&type=${v.name}`,
      });
    },
    /**
     * Добавление записи / мнения аудитора
     */
    async addRecord() {
      // Добавляем записи
      if (this.idEvaluation) {
        let data = this.formItems.items.reduce((acc, el) => {
          return {
            ...acc,
            ...{
              [el.name]:
                el.multi && el.model ? el.model.map((m) => m.id) : el.model,
            },
          };
        }, {});
        this.loadingAction = true;
        console.log(data);
        data.create_date = this.getToday();
        data.id_user = this.$auth.user().id;
        data.id_audit = this.id;
        let type = this.findEvaluationById(this.idEvaluation);
        type = type ? type.type : null;
        await this.addToCatalog(type, data);
        this.loadingAction = false;
        this.$router.push({
          path: `/audit?id=${this.id}&action=edit`,
        });
        await this.getDataFromDB();
      }

      // Добавляем мнения аудиотра
      if (this.action === "addOpinionAudit") {
        this.loadingAction = true;
        let data = this.formItems.items.reduce((acc, el) => {
          return {
            ...acc,
            ...{
              [el.name]:
                el.multi && el.model ? el.model.map((m) => m.id) : el.model,
            },
          };
        }, {});
        data.id_user = this.$auth.user().id;
        data.id_audit = this.id;
        await this.addToCatalog("opinions_auditor", data);
        this.loadingAction = false;
        this.$router.push({
          path: `/audit?id=${this.id}&action=edit`,
        });
        await this.getDataFromDB();
      }
    },
    /**
     * Инициализаия форм
     */
    initForm() {
      if (this.$route.query.action === "addRecord") {
        this.formItems = {
          flag: !true,
          items: [
            ...this.changeBlockForm(
              this.findEvaluationById(this.idEvaluation)
                ? this.findEvaluationById(this.idEvaluation).type
                : null
            ),
            {
              type: "select",
              name: "id_remark",
              label: "Замечание",
              items: [],
              findGetSubCatalog: {
                name: null,
                id_evaluation: Number(this.idEvaluation),
                id_entitie_evaluationf: null,
              },
              model: null,
              itemText: "name",
              required: true,
            },
            {
              type: "textarea",
              name: "details",
              label: "Подробности",
              model: null,
              itemText: "name",
              required: true,
            },
          ],
        };
      } else if (this.$route.query.action === "addOpinionAudit") {
        this.formItems = {
          items: [
            {
              type: "textarea",
              name: "opinion",
              label: "",
              model: null,
              required: true,
              block: true,
            },
          ],
        };
      }
    },

    /**
     * ПОлучене данных по обьекту из БД
     */
    async getDataFromDB() {
      this.loading = true;
      this.fullDataAudit = (
        await this.findCatalog("audits", {
          id: this.$route.query.id,
          id_role: this.$auth.user().id_role,
          id_user: ["manager", "client", "external_auditor"].includes(
            this.$auth.roles()
          )
            ? this.$auth.user().id
            : null,
        })
      ).data.items;
      this.loading = false;
    },
    async initData() {
      this.id = this.$route.query.id;
      this.action = this.$route.query.action;

      this.idUnitsEvaluation = this.$route.query.idUnitsEvaluation;
      this.idEvaluation = this.$route.query.idEvaluation;
      this.type = this.$route.query.type;

      this.setTitleAppBar("Аудит " + this.id);
      this.initActionList();
      this.initListUnitsEvaluation();
      this.initListRecords();
      this.initListOpinions();
      this.initDateOpen();
      this.initReport();
      this.initTree();
      this.initForm();

      this.$vuetify.goTo(this.$refs.startPage, this.optionGoTo);
    },
    /**
     * Инициализация отчета
     */
    initReport() {
      this.filterDynamic[0].model = [
        this.getTodayMinusDays(45),
        this.getTodayPlusDays(45),
      ];
      this.reportDynamic = [];
      this.report = [];
      this.reportNew = [];
      if (this.action === "report" && this.id) {
        this.report.push(
          ...(this.fullDataAudit && this.fullDataAudit.length
            ? [
                {
                  tab: "table",
                  chart: true,
                  title: "Оценки",
                  search: false,
                  fields: [
                    { name: "unit", align: "start", class: "cell" },
                    { name: "grade", align: "end", class: "cell" },
                  ],
                  items: this.fullDataAudit[0].report_by_unit_evaluation.filter(
                    (f) =>
                      ["client"].includes(this.$auth.roles())
                        ? this.permissClientUnit.includes(f.unit.toLowerCase())
                        : f
                  ),
                },
                {
                  tab: "table",
                  chart: true,
                  search: false,
                  fields: [
                    { name: "unit", align: "start", class: "cell" },
                    { name: "grade", align: "end", class: "cell" },
                  ],
                  title: "Итоговые баллы",
                  items: this.fullDataAudit[0].report_by_unit_entities.filter(
                    (f) =>
                      ["client"].includes(this.$auth.roles())
                        ? f.unit === "полная_оценка"
                        : f
                  ),
                },
                {
                  tab: "table",
                  search: false,
                  fields: [
                    {
                      name: "opinion",
                      align: "start",
                      class: "cell",
                    },
                  ],
                  title: "Мнения аудитора",
                  items: this.fullDataAudit[0].opinions_auditor,
                },
              ].filter((_r) => _r.items.length)
            : [])
        );

        console.log("reprt", this.report[1]);

        this.reportNew.push(
          ...(this.fullDataAudit && this.fullDataAudit.length
            ? [
                {
                  tab: "table",
                  exportPDF: true,
                  exportExcel: true,
                  title: "Отчеты по записям",
                  header: true,
                  headerColorBackgrond: "gray",
                  search: false,
                  name: "full_records",
                  fields: [
                    {
                      name: "id",
                      text: "id_записи",
                      align: "start",
                      class: "",
                      divider: true,
                      headerClass: ["grey lighten-3", "black--text"],
                    },
                    {
                      name: "id_audit",
                      text: "id_аудита",
                      align: "start",
                      class: "",
                      divider: true,
                      headerClass: ["grey lighten-3", "black--text"],
                    },
                    {
                      name: "object",
                      text: "имя_объекта",
                      align: "start",
                      class: "",
                      headerClass: ["grey lighten-3", "black--text"],
                    },
                    {
                      name: "create_date",
                      text: "дата_записи",
                      align: "start",
                      class: "",
                      divider: true,
                      headerClass: ["grey lighten-3", "black--text"],
                    },
                    {
                      name: "unit",
                      text: "блок_оценки",
                      align: "start",
                      sortable: true,
                      class: "",
                      divider: true,
                      headerClass: ["grey lighten-3", "black--text", "nowrap"],
                    },
                    {
                      name: "object_o",
                      text: "Объект наблюдения(id_сотрудника, участок или кладовая)",
                      align: "start",
                      class: "",
                      divider: true,
                      headerClass: ["grey lighten-3", "black--text"],
                    },
                    {
                      name: "evaluation",
                      text: "что_оцениваем",
                      align: "start",
                      class: "",
                      divider: true,
                      headerClass: ["grey lighten-3", "black--text"],
                    },
                    {
                      name: "surface",
                      text: "Поверхность",
                      align: "start",
                      class: "",
                      divider: true,
                      headerClass: ["grey lighten-3", "black--text"],
                    },
                    {
                      name: "remark",
                      text: "замечание",
                      align: "start",
                      class: "",
                      divider: true,
                      headerClass: ["grey lighten-3", "black--text"],
                    },
                    {
                      name: "details",
                      text: "подробности",
                      align: "start",
                      class: "",
                      divider: true,
                      headerClass: ["grey lighten-3", "black--text"],
                    },
                    {
                      name: "scores",
                      text: "вес замечания",
                      align: "start",
                      class: "",
                      divider: true,
                      headerClass: ["grey lighten-3", "black--text"],
                    },
                    {
                      name: "user",
                      text: "Автор записи",
                      align: "start",
                      class: "",
                      divider: true,
                      headerClass: ["grey lighten-3", "black--text"],
                    },
                  ],
                  items: this.fullDataAudit[0].full_records
                    .map((m) => {
                      return {
                        ...m,
                        create_date: new Date(m.create_date)
                          .toLocaleString("ru-RU")
                          .split(", ")[0],
                      };
                    })
                    .filter((f) =>
                      ["client"].includes(this.$auth.roles())
                        ? this.permissClientUnit.includes(f.unit.toLowerCase())
                        : f
                    ),
                },
              ]
            : [])
        );
      }
    },
    /**
     * Завершить аудит
     */
    async getActionBtnActionList(v) {
      console.log(v);
      this.loading = true;
      await this.updateToCatalog(
        "audits",
        {
          closed: true,
          close_date: this.convertDateToFormat(
            new Date().toLocaleString("ru-RU").split(", ")[0]
          ),
        },
        this.id
      );
      await this.getDataFromDB();
      this.initData();
      this.loading = false;
    },
    /**
     * Получение данных по "ОБЬЕКТ ОЦЕНКИ"
     */
    getDataUnitEvaluation() {
      this.unitsEvaluation = {
        name: "Качество",
        items: [
          // {
          //   id: -777,
          //   name: `Кабинеты, опен-спейсы`,
          //   title: "Кабинеты, опен-спейсы",
          //   color: "custom_red",
          //   icon: "",
          // },
          // {
          //   id: -999,
          //   name: `Санузлы`,
          //   title: "Санузлы",
          //   color: "custom_red",
          //   icon: "",
          // },
        ],
      };
    },

    /**
     * Получение данных по "ЧТО ОЦЕНИВАЕМ"
     */
    getDataEvaluation() {
      this.evaluation = {
        id: -9999,
        name: "Санузел",
      };
    },

    changeBlockForm(type) {
      switch (type) {
        case "records_area":
          return [
            {
              type: "select",
              name: "id_area",
              findGetSubCatalog: {
                name: null,
                id_audit: this.id,
              },
              label: "Участок",
              items: [],
              model: null,
              itemText: "name",
              required: true,
            },
            {
              type: "select",
              name: "id_surface",
              label: "Поверхность",
              items: [],
              model: null,
              itemText: "name",
              required: true,
            },
          ];
        case "records_employees":
          return [
            {
              type: "select",
              name: "id_employee",
              findGetSubCatalog: {
                name: null,
                sername: null,
                secondname: null,
                id_audit: this.id,
              },
              customVal: (m) => {
                return {
                  id: m.id,
                  name: `${m.name} ${m.sername} ${m.secondname}`,
                };
              },
              label: "Сотрудник",
              items: [],
              model: null,
              itemText: "name",
              required: true,
            },
          ];
        case "records_storeroom":
          return [
            {
              type: "select",
              name: "id_storeroom",
              findGetSubCatalog: {
                name: null,
                id_audit: this.id,
              },
              label: "Кладовая",
              items: [],
              model: null,
              itemText: "name",
              required: true,
            },
          ];
        case "records_workspaces":
          return [
            {
              type: "select",
              name: "id_workspace",
              findGetSubCatalog: {
                name: null,
                id_audit: this.id,
              },
              label: "Рабочее место",
              items: [],
              model: null,
              itemText: "name",
              required: true,
            },
          ];
        default:
          return [];
      }
    },

    /**
     * Поиск "Что ооцениваем" по ID
     */
    findEvaluationById(id) {
      let cur =
        this.fullDataAudit &&
        this.fullDataAudit.length &&
        this.fullDataAudit[0].evaluations
          ? this.fullDataAudit[0].evaluations
          : null;
      if (cur) {
        cur = cur.find((_f) => _f.id === Number(id));
      }
      return cur;
    },

    /**
     * Фильтрация "Что ооцениваем" от блока оценки
     */
    filterEvaluation(idUnitEvaluation) {
      return this.fullDataAudit && this.fullDataAudit.length
        ? this.fullDataAudit[0].evaluations
          ? this.fullDataAudit[0].evaluations.filter(
              (f) => f.id_unit_evaluation === Number(idUnitEvaluation)
            )
          : []
        : [];
    },
    initListOpinions() {
      this.listOpinions.items = [];
      if (this.action === "listOpinions") {
        this.listOpinions.items.push(
          ...(this.fullDataAudit &&
          this.fullDataAudit.length &&
          this.fullDataAudit[0].opinions_auditor
            ? this.fullDataAudit[0].opinions_auditor.map((m) => {
                return {
                  ...m,
                  title: `${m.opinion}`,
                  color: "custom_red",
                  disabled: false,
                };
              })
            : [])
        );
      }
    },

    initDateOpen() {
      this.dateOpen.items = [];
      if (this.action === "dateOpen") {
        const auditdata =
          this.fullDataAudit &&
          this.fullDataAudit.length &&
          this.fullDataAudit[0].opinions_auditor
            ? this.fullDataAudit[0]
            : {};
        this.dateOpen.items = [
          {
            title: new Date(auditdata.open_date)
              .toLocaleString("ru-RU")
              .split(", ")[0],
            source: `${auditdata.open_date}`,
            color: "custom_red",
            disabled: false,
          },
        ];
      }
    },

    initListRecords() {
      this.listRecords.items = [];
      if (this.action === "listRecords" && this.type) {
        this.listRecords.items.push(
          ...(this.fullDataAudit &&
          this.fullDataAudit.length &&
          this.fullDataAudit[0][this.type]
            ? this.fullDataAudit[0][this.type].map((m) => {
                return {
                  ...m,
                  title: `${m.scores}*${m.remark_name}`,
                  color: "custom_red",
                  disabled: false,
                  canDel: true,
                };
              })
            : [])
        );
      }
    },

    /**
     * Проверка хапоненгости блоков оценок
     */
    checkFillUnitsEvaluation(id) {
      const base =
        this.fullDataAudit && this.fullDataAudit.length
          ? this.fullDataAudit[0]
          : {};
      const evaluations = base?.evaluations;
      const records = base?.records;
      const records_area = base?.records_area;
      const records_employees = base?.records_employees;
      const records_workspaces = base?.records_workspaces;
      const records_storeroom = base?.records_storeroom;
      const all_records = [
        ...records,
        ...records_area,
        ...records_employees,
        ...records_workspaces,
        ...records_storeroom,
      ];

      //---
      const need_evaluations = evaluations.filter(
        (f) => f.id_unit_evaluation === id
      );
      const check = all_records.filter((f) =>
        need_evaluations.map((m) => m.id).includes(f.id_evaluation)
      );
      console.log("need_evaluations", id, need_evaluations, check);

      return check.length;
    },

    /**
     * Проверка заполнености  оценок
     */
    checkFillEvaluation(id) {
      const base =
        this.fullDataAudit && this.fullDataAudit.length
          ? this.fullDataAudit[0]
          : {};
      const records = base?.records;
      const records_area = base?.records_area;
      const records_employees = base?.records_employees;
      const records_workspaces = base?.records_workspaces;
      const records_storeroom = base?.records_storeroom;
      const all_records = [
        ...records,
        ...records_area,
        ...records_employees,
        ...records_workspaces,
        ...records_storeroom,
      ];

      //---
      const check = all_records.filter((f) => f.id_evaluation === id);

      return check.length;
    },

    /**
     * Инициализация списка блоков оценок
     */
    initListUnitsEvaluation() {
      this.listUnitsEvaluation.items = [];
      if (this.action === "addRecord" && !this.idUnitsEvaluation) {
        this.listUnitsEvaluation.items.push(
          ...(this.fullDataAudit && this.fullDataAudit.length
            ? this.fullDataAudit[0].units_evaluation.map((m) => {
                return {
                  ...m,
                  title: m.name,
                  color: this.checkFillUnitsEvaluation(m.id)
                    ? "custom_green"
                    : "custom_red",
                  disabled: !this.filterEvaluation(m.id).length,
                };
              })
            : [])
        );
      } else if (this.action === "addRecord" && this.idUnitsEvaluation) {
        console.log(
          this.filterEvaluation(this.idUnitsEvaluation).map((m) => {
            return {
              ...m,
              title: m.name,
              color: "custom_red",
            };
          })
        );
        this.listUnitsEvaluation.items.push(
          ...this.fullDataAudit[0].evaluations
            .filter(
              (f) => f.id_unit_evaluation === Number(this.idUnitsEvaluation)
            )
            .map((m) => {
              return {
                ...m,
                title: m.name,
                color: this.checkFillEvaluation(m.id)
                  ? "custom_green"
                  : "custom_red",
              };
            })
        );
      }
      if (
        this.action === "addRecord" &&
        this.idUnitsEvaluation &&
        this.idEvaluation
      ) {
        this.getDataEvaluation();
        // this.listUnitsEvaluation.items.push(...this.unitsEvaluation.items);
      }
    },
    /**
     * Кнлпка назад "ДЛИННОЕ НАЖАТИЕ"
     */
    getLongBtnClick(v) {
      console.log(v);
    },
    /**
     * Кнлпка назад
     */
    getBtnClick(v) {
      console.log(v);
      this.$router.go(-1);
      // switch (this.$route.query.action) {
      //   case "addRecord":
      //     if (!this.idUnitsEvaluation)
      //       location.href = `/audit?id=${this.id}&action=edit`;
      //     else if (this.idUnitsEvaluation)
      //       location.href = `/audit?id=${this.id}&action=addRecord`;
      //     if (this.idUnitsEvaluation && this.idEvaluation)
      //       location.href = `/audit?id=${this.id}&action=addRecord&idUnitsEvaluation=${this.idUnitsEvaluation}`;
      //     break;

      //   default:
      //     break;
      // }
    },
    actionAudit(v) {
      console.log(v);
      // location.href = `/${v.name}`;
      this.$router.push({
        path: v.name,
      });
    },
    getClickListItemUnitsEvaluation(v) {
      console.log(v);
      if (!this.idUnitsEvaluation) {
        // location.href = location.href + `&idUnitsEvaluation=${v.id}`;
        this.$router.push({
          path:
            this.$router.currentRoute.fullPath + `&idUnitsEvaluation=${v.id}`,
        });
      } else if (this.idUnitsEvaluation) {
        // location.href = location.href + `&idEvaluation=${v.id}`;
        this.$router.push({
          path: this.$router.currentRoute.fullPath + `&idEvaluation=${v.id}`,
        });
      }
    },
    /**
     * Инициализация дерева заголовка
     */
    initTree() {
      //const action = this.$route.query.action;
      this.tree = {
        color: "",
        marginB: "mb-2",
        items: [
          {
            name: "idAudit",
            items: [
              {
                name: "btnPrev",
                type: "btn",
                title: "",
                icon: "mdi-chevron-left",
              },
              {
                name: "leftIdAudit",
                title:
                  this.fullDataAudit && this.fullDataAudit.length
                    ? this.fullDataAudit[0].object
                    : null,
              },
              {
                name: "rightIdAudit",
                title:
                  this.fullDataAudit && this.fullDataAudit.length
                    ? new Date(this.fullDataAudit[0].clos_date)
                        .toLocaleString("ru-RU")
                        .split(", ")[0]
                    : null,
              },
            ],
            color: "custom_orange",
          },
        ],
      };
      switch (this.action) {
        case "addRecord":
          //Инициализируем лерерво заголовокв
          this.tree.items.push({
            name: this.action,
            border: true,
            items: [
              {
                name: this.action + "_0",
                title: "Добавление записи",
              },
            ],
            color: "custom_yellow",
          });
          //Добавляем в дерево заголовков "БЛОК ОЦЕНКИ"
          if (this.action === "addRecord" && this.idUnitsEvaluation) {
            this.tree.items[1].items.push({
              name: this.action + "_1",
              title:
                this.fullDataAudit && this.fullDataAudit.length
                  ? this.fullDataAudit[0].units_evaluation.find(
                      (f) => f.id === Number(this.idUnitsEvaluation)
                    ).name
                  : null,
            });
          }
          //Добавляем в дерево заголовков "ЧТО ОЦЕНИВАЕМ"
          if (this.action === "addRecord" && this.idEvaluation) {
            this.tree.items.push({
              name: this.action + this.idEvaluation,
              items: [
                {
                  name: this.action + this.idEvaluation + "_0",
                  title:
                    this.fullDataAudit && this.fullDataAudit.length
                      ? this.fullDataAudit[0].evaluations.find(
                          (f) => f.id === Number(this.idEvaluation)
                        ).name
                      : null,
                },
              ],
              color: "custom_yellow",
            });
          }
          break;
        case "addOpinionAudit":
          //Инициализируем лерерво заголовокв
          this.tree.items.push({
            name: this.action,
            border: true,
            items: [
              {
                name: this.action + "_0",
                title: "Добавление мнения аудитора",
              },
            ],
            color: "custom_yellow",
          });
          break;

        case "listRecords":
          //Инициализируем лерерво заголовокв
          this.tree.items.push({
            name: this.action,
            border: true,
            items: [
              {
                name: this.action + "_0",
                title: "Редактирование записи",
              },
            ],
            color: "custom_yellow",
          });
          break;
        case "report":
          //Инициализируем лерерво заголовокв
          this.tree.items.push({
            name: this.action,
            border: true,
            items: [
              {
                name: this.action + "_0",
                title: "Отчет",
              },
            ],
            color: "custom_yellow",
          });
          break;

        case "listOpinions":
          //Инициализируем лерерво заголовокв
          this.tree.items.push({
            name: this.action,
            border: true,
            items: [
              {
                name: this.action + "_0",
                title: "Мнения аудитора",
              },
            ],
            color: "custom_yellow",
          });
          break;

        case "dateOpen":
          //Инициализируем лерерво заголовокв
          this.tree.items.push({
            name: this.action,
            border: true,
            items: [
              {
                name: this.action + "_0",
                title: "Дата открытия аудита",
              },
            ],
            color: "custom_yellow",
          });
          break;

        default:
          break;
      }
    },
    /**
     * Инициализация активного списка
     */
    initActionList() {
      const self = this;
      this.list = {
        color: "blue lighten-3",
        btn: "Завершить аудит",
        items: [
          {
            get name() {
              return `audit?id=${self.id}&action=addRecord`;
            },
            title: "Добавить запись",
            color: "custom_blue",
            icon: "mdi-plus",
            disabled:
              this.fullDataAudit && this.fullDataAudit.length
                ? this.fullDataAudit[0].closed
                : false,
            show: !["client"].includes(this.$auth.roles()),
          },
          {
            get name() {
              return `audit?id=${self.id}&action=addOpinionAudit`;
            },
            title: "Добавить мнение аудитора",
            color: "custom_blue",
            icon: "mdi-plus",
            disabled:
              this.fullDataAudit && this.fullDataAudit.length
                ? this.fullDataAudit[0].closed
                : false,
            show: !["client"].includes(this.$auth.roles()),
          },
          {
            get name() {
              return `audit?id=${self.id}&action=report`;
            },
            title: "Посмотреть отчеты",
            color: "custom_blue",
            icon: "mdi-star",
          },
          {
            get name() {
              return `audit?id=${self.id}&action=listRecords`;
            },
            title: "Редактировать запись",
            color: "custom_yellow",
            icon: "mdi-pencil",
            disabled:
              this.fullDataAudit && this.fullDataAudit.length
                ? this.fullDataAudit[0].closed
                : false,
            show: !["client"].includes(this.$auth.roles()),
          },
          {
            get name() {
              return `audit?id=${self.id}&action=listOpinions`;
            },
            title: "Редактировать мнение аудитора",
            color: "custom_yellow",
            icon: "mdi-pencil",
            disabled:
              this.fullDataAudit && this.fullDataAudit.length
                ? this.fullDataAudit[0].closed
                : false,
            show: !["client"].includes(this.$auth.roles()),
          },
          {
            get name() {
              return `audit?id=${self.id}&action=dateOpen`;
            },
            title: "Редактировать дату открытия аудита",
            color: "custom_yellow",
            icon: "mdi-pencil",
            disabled:
              this.fullDataAudit && this.fullDataAudit.length
                ? this.fullDataAudit[0].closed
                : false,
            show: !["client"].includes(this.$auth.roles()),
          },
        ],
      };
    },
  },
};
</script>


<style scoped>
::v-deep .nowrap {
  white-space: nowrap !important;
}
</style>
