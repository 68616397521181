<template>
  <div class="px-5 h-100">
    <v-overlay opacity="0.8" z-index="12" :value="loadingAction">
      <v-row no-gutters justify="center">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-row>
      <v-row class="mt-3" no-gutters justify="center">
        <span class="subhead">Идет загрузка данных...</span>
      </v-row>
    </v-overlay>

    <v-skeleton-loader
      class="my-2"
      min-width="200"
      v-if="loading"
      type="table-cell@6"
    ></v-skeleton-loader>

    <v-list v-if="!loading">
      <template v-for="item in list.items">
        <v-col
          v-if="item.show === undefined || item.show"
          class="pa-0 ma-0 grey lighten-4"
          :key="item.name"
        >
          <v-row
            no-gutters
            align="center"
            :class="` ${list.dense ? 'my-3' : 'my-5'}`"
          >
            <v-col cols="12" sm>
              <v-list-item
                @click="clickListItem(item)"
                :class="`${!item.disabled ? item.color : 'grey lighten-2'}  ${
                  item.class
                }`"
                :style="
                  list.dense
                    ? 'min-height:35px !important; max-height:35px !important'
                    : ''
                "
                :disabled="item.disabled"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row no-gutters justify="start" class="w-100">
                      <v-col
                        style="overflow: hidden; text-overflow: ellipsis"
                        :cols="
                          item.title.split('*').length === 1
                            ? 12
                            : index === 0
                            ? 3
                            : (12 - 3) / (item.title.split('*').length - 1)
                        "
                        v-for="(title, index) in item.title.split('*')"
                        :key="title"
                      >
                        {{ title }}</v-col
                      >
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon v-if="item.icon" class="my-2">
                  <v-icon :size="list.dense ? '20' : '30'">{{
                    item.icon
                  }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-col>
            <v-col v-if="showRemove || showRepeat" sm="auto">
              <v-row no-gutters justify="end">
                <v-btn
                  :loading="loadAddRaw"
                  v-if="item.canRepeat"
                  @click="repeat(item)"
                  icon
                >
                  <v-icon title="Восстановить" color="blue darken-2" size="20"
                    >mdi-repeat</v-icon
                  >
                </v-btn>
                <v-btn
                  v-if="item.canDel"
                  @click="
                    item['name_catalog'] = list.name;
                    removeRaw = item;
                    $emit('openDialogRemove', true);
                  "
                  icon
                >
                  <v-icon title="Удалить" color="red lighten-2" size="20"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            v-if="item.more"
            justify="space-between"
            class="px-2"
          >
            <v-col
              :class="{
                'subtitle-2': true,
                'mb-2': true,
                'green--text text--darken-3': item.status_object === 'Запущен',
                'red--text text--darken-3': item.status_object === 'Закрыт',
                'blue--text text--darken-3': item.status_object === 'Заморозка',
                'orange--text text--darken-3': item.status_object === 'в УПР',
              }"
              cols="auto"
            >
              {{ item.status_object }}
            </v-col>
            <v-col cols="auto">
              <v-row no-gutters>
                <v-col
                  v-for="m in item.managers"
                  :key="m.name"
                  cols="auto"
                  :class="{
                    'ml-2': true,
                    'mb-2': true,
                    'blue--text': true,
                    'subtitle-2': true,
                  }"
                >
                  <v-chip small color="blue lighten-4">
                    {{ m.sername + " " + m.name }}</v-chip
                  >
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col cols="auto" class="ml-1">
              <v-btn
                style="font-size: 12px"
                class="mr-3"
                elevation="0"
                color="primary"
                x-small
                fab
                >{{ m.sername.slice(0, 1) + "" + m.name.slice(0, 1) }}</v-btn
              ></v-col
            > -->

            <v-col
              v-if="!item.managers.length"
              cols="auto"
              :class="{ 'grey--text': true, 'subtitle-2': true }"
              >Нет менеджера</v-col
            >
          </v-row>
        </v-col>
      </template>
    </v-list>

    <v-row no-gutters v-if="list.btn && !loading" justify="end">
      <v-btn
        color="grey lighten-2"
        class="new-btn"
        @click="actionBtn()"
        style="color: #000 !important"
        elevation="0"
        >{{ list.btn }}</v-btn
      >
    </v-row>

    <v-dialog persistent v-model="dialogRemove" max-width="500px">
      <v-card>
        <v-card-title class="text-h6"
          >Вы уверены что хотите удалить эту строку?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="new-btn"
            color="blue darken-1"
            text
            :disabled="loadAddRaw"
            @click="$emit('closeDialogRemove', false)"
            >Нет</v-btn
          >
          <v-btn
            class="new-btn"
            color="blue darken-1"
            text
            :loading="loadAddRaw"
            :disabled="loadAddRaw"
            @click="remove"
            >Да</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ParamsActinList from "./paramsActionList";
export default {
  props: [
    "list",
    "loading",
    "loadingAction",
    "dialogRemove",
    "initRemoveRaw",
    "loadAddRaw",
    "showRemove",
    "showRepeat",
  ],
  data() {
    return { ...ParamsActinList };
  },
  watch: {
    initRemoveRaw() {
      this.removeRaw = null;
    },
  },
  methods: {
    clickListItem(v) {
      this.$emit("getClickListItem", v);
    },
    remove() {
      this.$emit("remove", this.removeRaw);
    },
    repeat(v) {
      this.$emit("repeat", v);
    },
    actionBtn(v) {
      this.$emit("getActionBtnActionList", v);
    },
  },
};
</script>

<style scoped>
::v-deep .v-skeleton-loader__table-cell {
  align-items: center;
  display: flex;
  height: 60px;
  width: 100%;
}

::v-deep .v-skeleton-loader__text {
  border-radius: 6px;
  flex: 1 0 auto;
  height: 35px;
  margin-bottom: 5px;
}
</style>
