import { mdiCog, mdiTextBoxSearch } from "@mdi/js";
export default {
    list:
    {
        color: 'blue lighten-3',
        items: [
            {
                name: 'addAudit',
                title: 'Добавить аудит',
                color: 'custom_blue',
                icon: 'mdi-plus',
            },
            {
                name: 'auditList',
                title: 'Все аудиты',
                color: 'custom_blue',
                icon: mdiTextBoxSearch,
            },
            {
                name: 'reports',
                title: 'Посмотреть отчеты',
                color: 'custom_blue',
                icon: 'mdi-star',
            },
            {
                name: 'addObject',
                title: 'Добавить объект',
                color: 'custom_blue',
                icon: 'mdi-plus',
            },
            {
                name: '/settings/catalogs',
                title: 'Настройка справочников',
                color: 'custom_blue',
                class: 'd-none d-md-flex',
                icon: mdiCog,
            },
        ]
    }

}