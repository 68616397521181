<template>
  <div class="px-5">
    <v-overlay opacity="0.8" z-index="12" :value="loadingAction">
      <v-row no-gutters justify="center">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-row>
      <v-row class="mt-3" no-gutters justify="center">
        <span class="subhead">Идет загрузка данных...</span>
      </v-row>
    </v-overlay>

    <v-form ref="formAddRawCatalog">
      <v-row>
        <v-col
          cols="12"
          v-for="item in formItems.items"
          :key="item.name"
          :sm="item.block ? 12 : 6"
        >
          <v-col cols="auto" class="pa-0 mb-1">
            <span
              v-if="item.label && !loadSubCatalog"
              class="pa-1 custom_blue"
              >{{ item.label }}</span
            >
            <v-skeleton-loader
              min-width="200"
              width="200"
              v-if="loadSubCatalog"
              type="text"
            ></v-skeleton-loader>
          </v-col>

          <v-col cols="12" class="pa-0 mb-0">
            <v-skeleton-loader
              min-width="300"
              style="width: 100%"
              v-if="loadSubCatalog"
              type="heading"
            ></v-skeleton-loader>
            <v-text-field
              dense
              :rules="item.required ? [rules.required] : []"
              outlined
              background-color="#e7f6fd"
              v-if="item.type === 'text' && !loadSubCatalog"
              hide-details="auto"
              v-model="item.model"
            ></v-text-field>
            <v-autocomplete
              @change="
                (e) => {
                  item.model = e ? e.id : null;
                  if (item.multi) {
                    item.model = e;
                  }
                }
              "
              :multiple="item.multi"
              small-chips
              deletable-chips
              :loading="loadSubCatalog"
              dense
              :item-text="item.itemText"
              :rules="item.required ? [rules.required] : []"
              outlined
              v-model="item.model"
              hide-details="auto"
              background-color="#e7f6fd"
              v-if="item.type === 'select' && !loadSubCatalog"
              :items="item.items"
              item-value="id"
              return-object
            ></v-autocomplete>
            <v-textarea
              dense
              :rules="item.required ? [rules.required] : []"
              outlined
              background-color="#e7f6fd"
              auto-grow
              hide-details="auto"
              v-model="item.model"
              rows="2"
              v-if="item.type === 'textarea' && !loadSubCatalog"
            ></v-textarea>

            <v-menu
              v-if="item.type === 'date' && !loadSubCatalog"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  background-color="#e7f6fd"
                  :rules="item.required ? [rules.required] : []"
                  :value="dateText(item.model)"
                  prepend-inner-icon="mdi-calendar"
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker no-title v-model="item.model"></v-date-picker>
            </v-menu>
          </v-col>
        </v-col>
      </v-row>
    </v-form>

    <!-- <Bottom
      :loadingBottom="false"
      @initActive="initActive"
      :active="activeActionItems"
      :dataItems="actionItems"
      @apply="applyAddItem"
      :showSave="$auth.roles() === 'admin'"
    /> -->

    <v-row v-if="!loadSubCatalog" no-gutters justify="end" class="mt-6">
      <v-btn
        color="green lighten-2"
        class="new-btn"
        @click="actionBtn(formItems.items)"
        style="color: #000 !important"
        :disabled="
          !formItems.items
            .filter((f) => f.required)
            .every((e) => (e.multi && e.model ? e.model.length : e.model))
        "
        elevation="0"
        >Готово</v-btn
      >
    </v-row>
  </div>
</template>
<script>
import ParamsCommon from "./../../paramsCommon";
import catalogs from "./../../../plugins/catalogs";
export default {
  props: ["formItems", "loadingAction", "resetFormAddEdit"],
  data() {
    return {
      rules: ParamsCommon.rules,
      loadSubCatalog: ParamsCommon.loadSubCatalog,
    };
  },
  async mounted() {
    console.log(this.formItems);
    await this.getSubCatalog();
  },
  watch: {
    resetFormAddEdit() {
      this.$refs.formAddRawCatalog.reset();
    },
    formItems: {
      handler(v) {
        console.log("v", v);
      },
      depp: true,
    },
  },
  methods: {
    ...catalogs,

    dateText(period) {
      const date = period.split("-");
      return `${date[2]}.${date[1]}.${date[0]}`;
    },

    // openBottomPanelChoiseItem(item) {
    //   this.bottomPanelChoiseItem = true;
    //   this.bottomPanelItems.items = item.items;
    //   this.bottomPanelItems.multi = item.multi;
    //   this.bottomPanelItems.label = item.label;

    //   console.log(item, this.bottomPanelChoiseItem);
    // },
    actionBtn(v) {
      this.$emit("getActionBtn", v);
    },
    async getSubCatalog() {
      this.loadSubCatalog = true;
      for (let i = 0; i < this.formItems.items.length; i++) {
        const catalog = this.formItems.items[i];
        if (catalog.type == "select") {
          if (!catalog.findGetSubCatalog) {
            this.itemsSub = (
              await this.getCatalog(this.prepareSubCatalogsNAme(catalog.name))
            ).data.items;
          } else {
            console.log("catalog", catalog.findGetSubCatalog);
            this.itemsSub = (
              await this.findCatalog(
                this.prepareSubCatalogsNAme(catalog.name),
                catalog.findGetSubCatalog
              )
            ).data.items;
          }

          catalog.items = this.itemsSub.map(
            catalog.customVal ? catalog.customVal : (m) => m
          );
          if (catalog.defaultModel) {
            catalog.model = this.itemsSub;
          }
        }
      }
      this.loadSubCatalog = false;

      return this.itemsSub;
    },
  },
};
</script>
<style scoped>
::v-deep .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #e57373 !important;
  /* font-weight: 700; */
}
</style>

<style scoped>
::v-deep .v-select.v-input--dense .v-chip {
  margin: 1px 4px;
}
::v-deep .v-skeleton-loader__heading {
  border-radius: 12px;
  height: 24px;
  width: 90%;
}
</style>
