<template>
  <div>
    <v-app>
      <v-app-bar v-if="this.$auth.check()" flat light app>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title class="font-weight-bold">{{ title }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <!-- v-if="this.$auth.check()" -->
        <v-col cols="auto">
          <v-menu :close-on-content-click="false" left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                style="font-size: 20px"
                class="ml-3"
                elevation="0"
                color="primary"
                small
                fab
                >{{ generateAvatar() }}</v-btn
              >
            </template>
            <div style="min-width: 320px; z-index: 2" class="white">
              <Account />
            </div>
          </v-menu>
        </v-col>
      </v-app-bar>

      <v-main>
        <router-view></router-view>
      </v-main>

      <v-navigation-drawer
        v-if="drawer"
        v-model="drawer"
        app
        bottom
        dark
        temporary
      >
        <!-- ($auth.roles() !== 'admin'
                ? !['Настройки', 'Пользователи', 'Добавить объект'].includes(
                    f.name
                  )
                : 1 === 1)) -->
        <router-link
          v-for="item in menu.filter(
            (f) =>
              !f.sub_items && !notAccess.menu[$auth.roles()].includes(f.name)
          )"
          :key="item.name"
          tag="v-list-item"
          :to="item.path"
          active-class="active"
          exact
          :class="item.class"
        >
          <!-- <v-list-item link> -->
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="text-xs">
              {{ item.name }}</v-list-item-title
            >
          </v-list-item-content>
          <!-- </v-list-item> -->
        </router-link>
        <template v-slot:append>
          <p class="text-center white--text caption mb-2">
            <a
              title="Support in Telegram"
              target="_blank"
              href="https://t.me/+WNNGvZHNyYo5NmRi"
              class="custom_link"
              style="text-decoration: none; color: white"
            >
              <v-row no-gutters justify="center" align="center"
                >Support chat
                <v-img
                  class="ml-2"
                  max-width="15"
                  max-height="15"
                  :src="require('./assets/social/telegram.png')"
                ></v-img> </v-row
            ></a>
          </p>

          <p class="text-center white--text caption">
            Produced by SoftMagic 2021
          </p>
        </template>
      </v-navigation-drawer>

      <!-- <v-footer app>
        <p class="w-100 text-center white--black caption ma-0">
          Produced by MagicSoft 2021
        </p>
      </v-footer> -->
    </v-app>
  </div>
</template>

<script>
import {
  mdiCogOutline,
  mdiTextBoxSearchOutline,
  mdiClockTimeFiveOutline,
} from "@mdi/js";
import Account from "./components/auth/accountMenu";
import Helper from "./plugins/helper";
import ParamsCommon from "./components/paramsCommon";
export default {
  // name: "App",
  components: {
    Account,
  },
  data() {
    return {
      notAccess: ParamsCommon.notAccess,
      title: "",
      drawer: false,
      menu: [
        {
          name: "Главная",
          path: "/",
          disabled: false,
          icon: "mdi-view-split-vertical",
        },
        {
          name: "Добавить аудит",
          path: "/addAudit",
          disabled: false,
          icon: "mdi-plus",
        },
        {
          name: "Все аудиты",
          path: "/auditList",
          disabled: false,
          icon: mdiTextBoxSearchOutline,
        },
        // {
        //   name: "Посмотреть отчеты",
        //   path: "/reports",
        //   disabled: false,
        //   icon: "mdi-star",
        // },
        {
          name: "Добавить объект",
          path: "/addObject",
          disabled: false,
          icon: "mdi-plus",
        },
        {
          name: "Все объекты",
          path: "/objectList",
          disabled: false,
          icon: mdiTextBoxSearchOutline,
        },
        {
          name: "События объектов",
          path: "/objectRegistry",
          disabled: false,
          icon: mdiClockTimeFiveOutline,
        },
        {
          name: "Пользователи",
          class: "d-none d-md-flex",
          path: "/users",
          disabled: false,
          icon: "mdi-account-multiple-outline",
        },
        {
          name: "Настройки",
          class: "d-none d-md-flex",
          path: "/settings/catalogs",
          model: null,
          disabled: false,
          // sub_items: [
          //   {
          //     name: "Справочники",
          //     path: "/settings/catalogs",
          //     disabled: false,
          //   },
          // ],
          icon: mdiCogOutline,
        },
      ],
    };
  },
  methods: {
    ...Helper,
  },
};
</script>

<style>
.stickyTree {
  position: sticky;
  top: 60px;
  z-index: 1;
}
.w-100 {
  width: 100%;
}
.new-btn {
  text-transform: none !important;
  text-decoration: none !important;
  font-size: 16px !important;
  letter-spacing: 0 !important;
  text-indent: 0 !important;
}
.link-btn {
  text-transform: none !important;
  text-decoration: underline !important;
  letter-spacing: 0 !important;
  text-indent: 0 !important;
}
.close-btn {
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
}
.h-100 {
  height: 100%;
}
.main-menu-main {
  background: #fff !important;
}
.active_menu {
  color: white !important;
}
.custom_orange {
  background: #ffb986 !important;
}
.custom_yellow {
  background: #fdf1d3 !important;
}
.custom_blue {
  background: #92d7f4 !important;
}
.custom_blue_light {
  background: #e7f6fd !important;
}
.custom_red {
  background: #ef734c !important;
}
.custom_green {
  background: #5fbb58 !important;
}
</style>

