<template>
  <div>
    <v-row no-gutters class="w-100 h-100" align="center" justify="center">
      <v-icon color="grey lighten-2" size="100">mdi-cancel</v-icon>
    </v-row>
    <v-row no-gutters class="w-100 h-100" align="center" justify="center">
      <h1 class="text-center grey--text text--lighten-2">
        Нет даннх для отчета
      </h1>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>