<template>
  <div class="px-5">
    <v-list>
      <v-skeleton-loader
        min-width="300"
        style="width: 100%"
        v-if="loading"
        type="heading"
      ></v-skeleton-loader>
      <v-skeleton-loader
        min-width="300"
        style="width: 100%"
        class="my-3"
        v-if="loading"
        type="heading"
      ></v-skeleton-loader>
      <v-skeleton-loader
        min-width="300"
        style="width: 100%"
        v-if="loading"
        type="heading"
      ></v-skeleton-loader>
      <v-row
        justify="start"
        no-gutters
        v-for="item in tree.items"
        :key="item.name"
        :class="`${item.color} ${tree.marginB}`"
        :style="
          item.border ? 'border:1px solid rgba(0, 0, 0, 0.3) !important' : ''
        "
      >
        <template v-for="(itemTitle, index) in item.items">
          <v-col :cols="'auto'" :key="itemTitle.name">
            <v-list-item
              :class="itemTitle.type === 'btn' ? 'px-1' : ''"
              :style="`
              overflow: hidden;
              max-height: 40px;
              min-height: 40px;
              ${index !== 0 ? 'border-left: 1px solid rgba(0, 0, 0, 0.3)' : ''}
            `"
            >
              <v-list-item-content v-if="itemTitle.type !== 'btn'" class="pa-0">
                <v-list-item-title>
                  {{ itemTitle.title }}
                </v-list-item-title>
              </v-list-item-content>
              <v-btn
                v-longclick="() => longBtnClick(itemTitle)"
                @click="btnClick(itemTitle)"
                small
                v-else
                icon
                ><v-icon>{{ itemTitle.icon }}</v-icon></v-btn
              >
            </v-list-item>
          </v-col>
          <v-spacer :key="index" v-if="index === item.items.length - 2">
          </v-spacer>
        </template>
      </v-row>
    </v-list>
  </div>
</template>

<script>
export default {
  props: ["tree", "loading"],
  data() {
    return {};
  },
  methods: {
    btnClick(v) {
      this.$emit("getBtnClick", v);
    },
    longBtnClick(v) {
      this.$emit("getLongBtnClick", v);
    },
  },
};
</script>

<style scoped>
::v-deep .v-skeleton-loader__heading {
  border-radius: 12px;
  height: 24px;
  width: 100%;
}
</style>