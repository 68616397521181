var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5 mt-2"},[(_vm.title)?_c('h2',{staticClass:"black--text",staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[(_vm.filterDate)?_c('SearchBlock',{staticStyle:{"z-index":"10"},attrs:{"loadFinding":_vm.loadFinding,"loadDataFilter":_vm.loadDataFilter,"filter":_vm.filterDynamic},on:{"find":function($event){return _vm.$emit('find')}}}):_vm._e()],1),(_vm.loadFinding)?_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":"","reverse":""}}):_vm._e()],1),(_vm.units.length && _vm.units.find(function (e) { return e.search; }))?_c('v-text-field',{staticClass:"mb-3",attrs:{"dense":"","append-icon":"mdi-magnify","label":"Поиск по оценкам","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),(!_vm.units.length && _vm.filterDate)?_c('h2',{staticClass:"grey--text text--lighten-1 mt-3 text-center"},[_vm._v(" В эти даты аудитов нет ")]):_vm._e(),_c('v-row',{class:{ 'mt-0': _vm.filterDate }},_vm._l((_vm.units),function(unit,index){return _c('v-col',{key:unit.name,attrs:{"cols":"12","sm":index === _vm.units.length - 1 && _vm.units.length % 2 !== 0 ? 12 : 6}},[_c('v-card',{staticClass:"mx-auto",staticStyle:{"border":"1px solid gray"},attrs:{"min-width":"100%","elevation":"0"}},[_c('v-card-text',[_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":"","align":"center"}},[_c('h2',{staticClass:"black--text",staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(unit.title)+" ")]),(unit.chart)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"blue lighten-2","icon":"","small":""},on:{"click":function($event){unit.tab = 'table'}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-table")])],1)]}}],null,true)},[_c('span',[_vm._v("Показать таблицу")])]):_vm._e(),(unit.chart)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"blue lighten-2","icon":"","small":""},on:{"click":function () {
                      unit.tab = 'chart';
                    }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-chart-line")])],1)]}}],null,true)},[_c('span',[_vm._v("Показать график")])]):_vm._e(),(unit.chart)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"blue lighten-2","icon":"","small":""},on:{"click":function () {
                      unit.tab = 'chart-bar';
                    }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-chart-bar")])],1)]}}],null,true)},[_c('span',[_vm._v("Показать диаграмму")])]):_vm._e(),(unit.exportPDF)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"red lighten-2","icon":"","small":""},on:{"click":function($event){return _vm.exportOut('PDF', unit)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-file-pdf-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Скачать в PDF")])]):_vm._e(),(unit.exportExcel)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"teal","icon":"","small":""},on:{"click":function($event){return _vm.exportOut('Excel', unit)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-file-excel-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Скачать в Excel")])]):_vm._e()],1),(unit.tab === 'table')?_c('v-data-table',{attrs:{"no-data-text":"Данные не найдены","no-results-text":"Данные не найдены","search":unit.search ? _vm.search : '',"mobile-breakpoint":"0","dense":"","hide-default-footer":unit.name !== 'full_records',"hide-default-header":!unit.header,"headers":unit.fields.map(function (m) {
                return {
                  value: m.name,
                  text: m.text ? m.text : m.name,
                  filterable: true,
                  sortable: m.sortable,
                  align: m.align,
                  cellClass: m.class,
                  class: m.headerClass,
                  divider: m.divider,
                };
              }),"items":unit.items},scopedSlots:_vm._u([(_vm.sparkline)?{key:"item.sparkline",fn:function(ref){
              var item = ref.item;
return [_c('v-sparkline',{staticStyle:{"width":"100px"},attrs:{"line-width":4,"smooth":3,"value":Object.values(item).filter(function (f) { return typeof f === 'number'; }),"auto-draw":""}})]}}:null],null,true)}):_vm._e(),(unit.chart && unit.tab === 'chart')?_c('line-chart',{staticStyle:{"position":"relative","max-height":"160px"},attrs:{"chartdata":{
              labels: unit.items.map(function (_m) { return _m.unit; }),
              datasets: [
                {
                  label: 'Блоки оценок',
                  fill: true,
                  borderColor: '#90CAF9',
                  backgroundColor: '#E3F2FD',
                  order: 1,
                  borderWidth: 2,
                  data: unit.items.map(function (_m) { return _m.grade; }),
                } ],
            },"options":{
              responsive: true,
              maintainAspectRatio: false,
              height: 100,
              layout: {
                padding: 0,
              },
              legend: {
                display: false,
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  } ],
                xAxes: [
                  {
                    display: false,
                    ticks: {
                      maxTicksLimit: 3, // максимальное количество значений (и рёбер сетки) по оси x
                    },
                  } ],
              },
            }}}):_vm._e(),(unit.chart && unit.tab === 'chart-bar')?_c('horizontal-bar-chart',{staticStyle:{"position":"relative","max-height":"160px"},attrs:{"chartdata":{
              labels: unit.items.map(function (_m) { return _m.unit; }),
              datasets: [
                {
                  label: 'Блоки оценок',
                  fill: false,

                  order: 1,
                  borderWidth: 2,
                  backgroundColor: unit.items.map(function (_m) { return _m.grade > 0 ? '#B2DFDB' : '#FFCDD2'; }
                  ),
                  borderColor: unit.items.map(function (_m) { return _m.grade > 0 ? '#80CBC4' : '#EF9A9A'; }
                  ),
                  data: unit.items.map(function (_m) { return _m.grade; }),
                } ],
            },"options":{
              responsive: true,
              maintainAspectRatio: false,
              height: 100,
              layout: {
                padding: 0,
              },
              legend: {
                display: false,
              },
              scales: {
                xAxes: [
                  {
                    display: false,
                    ticks: {
                      beginAtZero: true,
                      maxTicksLimit: 3, // максимальное количество значений (и рёбер сетки) по оси x
                    },
                  } ],
              },
            }}}):_vm._e()],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }