var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5 h-100"},[_c('v-overlay',{attrs:{"opacity":"0.8","z-index":"12","value":_vm.loadingAction}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":"","justify":"center"}},[_c('span',{staticClass:"subhead"},[_vm._v("Идет загрузка данных...")])])],1),(_vm.loading)?_c('v-skeleton-loader',{staticClass:"my-2",attrs:{"min-width":"200","type":"table-cell@6"}}):_vm._e(),(!_vm.loading)?_c('v-list',[_vm._l((_vm.list.items),function(item){return [(item.show === undefined || item.show)?_c('v-col',{key:item.name,staticClass:"pa-0 ma-0 grey lighten-4"},[_c('v-row',{class:(" " + (_vm.list.dense ? 'my-3' : 'my-5')),attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":""}},[_c('v-list-item',{class:((!item.disabled ? item.color : 'grey lighten-2') + "  " + (item.class)),style:(_vm.list.dense
                  ? 'min-height:35px !important; max-height:35px !important'
                  : ''),attrs:{"disabled":item.disabled},on:{"click":function($event){return _vm.clickListItem(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{staticClass:"w-100",attrs:{"no-gutters":"","justify":"start"}},_vm._l((item.title.split('*')),function(title,index){return _c('v-col',{key:title,staticStyle:{"overflow":"hidden","text-overflow":"ellipsis"},attrs:{"cols":item.title.split('*').length === 1
                          ? 12
                          : index === 0
                          ? 3
                          : (12 - 3) / (item.title.split('*').length - 1)}},[_vm._v(" "+_vm._s(title))])}),1)],1)],1),(item.icon)?_c('v-list-item-icon',{staticClass:"my-2"},[_c('v-icon',{attrs:{"size":_vm.list.dense ? '20' : '30'}},[_vm._v(_vm._s(item.icon))])],1):_vm._e()],1)],1),(_vm.showRemove || _vm.showRepeat)?_c('v-col',{attrs:{"sm":"auto"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[(item.canRepeat)?_c('v-btn',{attrs:{"loading":_vm.loadAddRaw,"icon":""},on:{"click":function($event){return _vm.repeat(item)}}},[_c('v-icon',{attrs:{"title":"Восстановить","color":"blue darken-2","size":"20"}},[_vm._v("mdi-repeat")])],1):_vm._e(),(item.canDel)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){item['name_catalog'] = _vm.list.name;
                  _vm.removeRaw = item;
                  _vm.$emit('openDialogRemove', true);}}},[_c('v-icon',{attrs:{"title":"Удалить","color":"red lighten-2","size":"20"}},[_vm._v("mdi-delete")])],1):_vm._e()],1)],1):_vm._e()],1),(item.more)?_c('v-row',{staticClass:"px-2",attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{class:{
              'subtitle-2': true,
              'mb-2': true,
              'green--text text--darken-3': item.status_object === 'Запущен',
              'red--text text--darken-3': item.status_object === 'Закрыт',
              'blue--text text--darken-3': item.status_object === 'Заморозка',
              'orange--text text--darken-3': item.status_object === 'в УПР',
            },attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(item.status_object)+" ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((item.managers),function(m){return _c('v-col',{key:m.name,class:{
                  'ml-2': true,
                  'mb-2': true,
                  'blue--text': true,
                  'subtitle-2': true,
                },attrs:{"cols":"auto"}},[_c('v-chip',{attrs:{"small":"","color":"blue lighten-4"}},[_vm._v(" "+_vm._s(m.sername + " " + m.name))])],1)}),1)],1),(!item.managers.length)?_c('v-col',{class:{ 'grey--text': true, 'subtitle-2': true },attrs:{"cols":"auto"}},[_vm._v("Нет менеджера")]):_vm._e()],1):_vm._e()],1):_vm._e()]})],2):_vm._e(),(_vm.list.btn && !_vm.loading)?_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-btn',{staticClass:"new-btn",staticStyle:{"color":"#000 !important"},attrs:{"color":"grey lighten-2","elevation":"0"},on:{"click":function($event){return _vm.actionBtn()}}},[_vm._v(_vm._s(_vm.list.btn))])],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialogRemove),callback:function ($$v) {_vm.dialogRemove=$$v},expression:"dialogRemove"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v("Вы уверены что хотите удалить эту строку?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"new-btn",attrs:{"color":"blue darken-1","text":"","disabled":_vm.loadAddRaw},on:{"click":function($event){return _vm.$emit('closeDialogRemove', false)}}},[_vm._v("Нет")]),_c('v-btn',{staticClass:"new-btn",attrs:{"color":"blue darken-1","text":"","loading":_vm.loadAddRaw,"disabled":_vm.loadAddRaw},on:{"click":_vm.remove}},[_vm._v("Да")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }