import Helper from "./../../../plugins/helper";

export default {
    self_: this,
    formItems: {
        items: [
            {
                type: 'select',
                name: 'id_object',

                label: 'Обьект',
                items: [{ id: 1, name: 'no' }, { id: 2, name: 'ok' }],
                model: null,
                itemText: 'name',

                required: true,
                block: true,

            },
            {
                type: 'date',
                name: 'open_date',

                label: 'Дата открытия аудита',
                model: Helper.getToday(),
                itemText: 'name',
                required: true,
            },
            {
                type: 'date',
                name: 'close_date',

                label: 'Дата закрытия аудита',
                model: Helper.getTodayPlusDays(30),
                itemText: 'name',
                required: true,
            }
        ]
    },
    tree: {
        color: '',
        marginB: 'mb-2',
        items: [
            {
                name: 'idAudit',
                items: [
                    {
                        name: 'leftIdAudit',
                        title: 'ID аудита',
                    },
                    {
                        name: 'rightIdAudit',
                        title: '134',
                    },

                ],
                color: 'custom_orange',
            },
            // {
            //     name: 'addRecord',
            //     border: true,
            //     items: [
            //         {
            //             name: 'leftAddRecord',
            //             title: 'Добавление записи',
            //         },
            //         {
            //             name: 'rightAddRecord',
            //             title: 'Качество',
            //         },

            //     ],
            //     color: 'custom_yellow',
            // },
            // {
            //     name: 'evaluation',
            //     border: false,
            //     items: [
            //         {
            //             name: 'leftEvaluation',
            //             title: 'Санузел',
            //         },
            //         // {
            //         //     name: 'rightAddRecord',
            //         //     title: 'Качество',
            //         // },

            //     ],
            //     color: 'custom_yellow',
            // },
        ]
    },



}