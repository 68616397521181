<template>
  <div style="position: relative">
    <div class="mt-3" v-if="!loading">
      <a
        @click="hiddenFilter = hiddenFilter === 0 ? -1 : 0"
        class="ml-6 caption blue--text text--darken-2"
      >
        {{ hiddenFilter === 0 ? "Свернуть" : "Развернуть" }} фильтр
        <v-icon color="blue darken-2" size="20">{{
          hiddenFilter === 0 ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon></a
      >

      <v-expansion-panels class="mt-2" flat v-model="hiddenFilter">
        <v-expansion-panel>
          <v-expansion-panel-content>
            <v-row no-gutters class="grey lighten-5">
              <Search
                :loadDataFilter="loadSubCatalog"
                @getCatalogFromBlock="getSubCatalog"
                :filter="findItems"
                @find="find"
              />
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <ActionList
      :loadingAction="false"
      :loading="loading"
      :loadAddRaw="loadAddRaw"
      @getClickListItem="clicckObject"
      :dialogRemove="dialogRemove"
      @closeDialogRemove="closeDialogRemove"
      @openDialogRemove="openDialogRemove"
      @remove="removeItem"
      @repeat="repeatItem"
      :list="list"
      :showRemove="['admin'].includes($auth.roles())"
      :showRepeat="['admin'].includes($auth.roles())"
    />
    <ErrorAuditsNo v-if="!list.items.length && !loading" />
  </div>
</template>
<script>
import Search from "./../../units/search/search.vue";
import Helper from "../../../plugins/helper";
import catalogs from "./../../../plugins/catalogs";
import ActionList from "./../../units/actionList/actionList.vue";
import ParamsAuditList from "./paramsAuditList";
import ErrorAuditsNo from "./../../views/errors/notAudit.vue";
import commonVal from "./../../paramsCommon";
export default {
  components: {
    ActionList,
    ErrorAuditsNo,
    Search,
  },
  data() {
    return {
      ...ParamsAuditList,
      loading: commonVal.loading,
      dialogRemove: commonVal.dialogRemove,
      loadAddRaw: commonVal.dialogRemove,
      loadSubCatalog: commonVal.loadSubCatalog,
    };
  },
  async mounted() {
    this.setTitleAppBar("Все аудиты");
    await this.initData();
  },

  methods: {
    ...Helper,
    ...catalogs,

    async find() {
      await this.get();
    },

    async getSubCatalog(name) {
      this.loadSubCatalog = true;

      this.itemsSub = (
        await this.getCatalog(this.prepareSubCatalogsNAme(name))
      ).data.items;

      this.findItems.forEach((selects) => {
        if (selects.name === name) {
          selects.items = this.itemsSub;
        }
      });

      this.loadSubCatalog = false;

      return this.itemsSub;
    },

    /**
     * Восстановление аудита
     */
    async repeatItem(v) {
      this.loadAddRaw = true;
      await this.updateToCatalog(
        "audits",
        {
          closed: false,
          close_date: this.getDatePlusDays(new Date(v.open_date), 30),
        },
        v.id
      );
      this.loadAddRaw = false;
      await this.initData();
    },

    /**
     * Удаление аудита
     */
    async removeItem(v) {
      console.log(v);
      this.loadAddRaw = true;
      await this.removeFromCatalog("audits", v.id);
      this.loadAddRaw = false;
      this.dialogRemove = false;
      // this.tree = {};
      // await this.getDataFromDB();
      await this.initData();

      this.removeRaw = !this.removeRaw;
    },

    async initData() {
      await this.get();
    },
    clicckObject(a) {
      this.$router.push({ path: `audit?id=${a.id}&action=edit` });
    },
    async get() {
      this.loading = true;
      const allAudits = (
        await this.findCatalog("audits", {
          id: null,
          id_role: this.$auth.user().id_role,
          id_user: ["manager", "client", "external_auditor"].includes(
            this.$auth.roles()
          )
            ? this.$auth.user().id
            : null,
          id_object: this.findItems.find((f) => f.name === "id_object").model,
          without_grade: this.findItems.find((f) => f.name === "without_grade")
            .model,
        })
      ).data.items;

      this.list.items = allAudits.map((m) => {
        return {
          id: m.id,
          name: m.name,
          title: `${m.id}*${m.object}`,
          color: m.closed ? "custom_red" : "custom_green",
          open_date: m.open_date,
          icon: "",
          canDel: true,
          canRepeat: m.closed,
        };
      });
      console.log(allAudits);
      this.loading = false;
    },
  },
};
</script>
