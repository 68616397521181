export default {
    id: -1,
    action: null,
    fullDataObject: null,
    selectedItems: [],
    actionItems: {
        label: '',
        multi: false,
        items: []
    },
    activeActionItems: false,
    tree: {

    },
    list: {
        color: "blue lighten-3",
        btn: "Завершить аудит",
        items: [
        ]
    },

    formItems: {
        items: [
            // {
            //     type: 'select',
            //     name: 'areas',

            //     label: 'Участок',
            //     items: [{ id: 1, name: 'no' }, { id: 2, name: 'ok' }],
            //     model: null,
            //     itemText: 'name',
            //     required: true,

            // },
            // {
            //     type: 'select',
            //     name: 'surfaces',
            //     label: 'Поверхность',
            //     items: [{ id: 1, name: 'no' }, { id: 2, name: 'ok' }],
            //     model: null,
            //     itemText: 'name',
            //     required: true,
            // },
            // {
            //     type: 'select',
            //     name: 'remarks',
            //     label: 'Замечание',
            //     items: [{ id: 1, name: 'no' }, { id: 2, name: 'ok' }],
            //     model: null,
            //     itemText: 'name',
            //     required: true,
            // },
            // {
            //     type: 'textarea',
            //     name: 'details',
            //     label: 'Пожробности',
            //     model: null,
            //     itemText: 'name',
            //     required: true,
            // },
        ]
    },
}