<template>
  <div class="h-100">
    <ActionList
      :loadingAction="false"
      @getClickListItem="getClickListItem"
      :list="list"
    />
  </div>
</template>
<script>
import Helper from "./../../../plugins/helper";
import ParamsHome from "./paramsHome";
import ActionList from "./../../units/actionList/actionList.vue";
import ParamsCommon from "./../../../components/paramsCommon";
import { mdiCog, mdiTextBoxSearch } from "@mdi/js";
export default {
  components: {
    ActionList,
  },
  data() {
    return { ...ParamsHome, notAccess: ParamsCommon.notAccess };
  },
  mounted() {
    this.initAccess();
    this.setTitleAppBar("Главная");
  },
  methods: {
    ...Helper,
    initAccess() {
      this.list = {
        color: "blue lighten-3",
        items: [
          {
            name: "addAudit",
            title: "Добавить аудит",
            color: "custom_blue",
            icon: "mdi-plus",
          },
          {
            name: "auditList",
            title: "Все аудиты",
            color: "custom_blue",
            icon: mdiTextBoxSearch,
          },
          // {
          //   name: "reports",
          //   title: "Посмотреть отчеты",
          //   color: "custom_blue",
          //   icon: "mdi-star",
          // },
          {
            name: "addObject",
            title: "Добавить объект",
            color: "custom_blue",
            icon: "mdi-plus",
          },
          {
            name: "/settings/catalogs",
            title: "Настройка справочников",
            color: "custom_blue",
            class: "d-none d-md-flex",
            icon: mdiCog,
          },
        ],
      };
      this.list.items = this.list.items.filter(
        (f) => !this.notAccess.menu[this.$auth.roles()].includes(f.title)
      );
    },
    getClickListItem(v) {
      // location.href = "/" + v.name;
      this.$router.push({
        path: v.name,
      });
    },
  },
};
</script>
