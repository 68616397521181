var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"ma-3",staticStyle:{"position":"relative"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-0",staticStyle:{"min-width":"200px"},attrs:{"sm":"auto"}},[_c('v-row',{staticClass:"pt-2",staticStyle:{"touch-action":"none !important"},attrs:{"align":"center","no-gutters":"","justify":"start"}},[_c('v-col',[_c('v-list',{},[_c('v-list-item-group',{attrs:{"mandatory":"","color":"primary"},model:{value:(_vm.sectionMoreCatalog),callback:function ($$v) {_vm.sectionMoreCatalog=$$v},expression:"sectionMoreCatalog"}},_vm._l((_vm.dataTable),function(catalog){return _c('v-list-item',{key:catalog.name,attrs:{"value":catalog}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(catalog.title))])],1)],1)}),1)],1)],1)],1)],1),_c('v-col',{staticClass:"pa-4",staticStyle:{"min-width":"300px"},attrs:{"sm":""}},[(_vm.loadCatalog)?_c('v-skeleton-loader',{attrs:{"min-width":"300","type":"table-heading, list-item-three-line"}}):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[(_vm.selectMoreCatalog.table)?_c('div',{staticClass:"w-100"},[_c('TableUnit',{attrs:{"actionsButtons":true,"infoReport":_vm.infoReport,"loadAddRaw":_vm.loadAddRaw,"loading":_vm.loading,"itemClasses":_vm.itemClasses,"dataTable":_vm.selectMoreCatalog,"dialogAddItem":_vm.dialogAddItem,"dialogRemove":_vm.dialogRemove,"loadSubCatalog":_vm.loadSubCatalog,"resetFormAddEdit":_vm.resetFormAddEdit,"initRemoveRaw":_vm.removeRaw,"initMultiSelected":_vm.initMultiSelected,"editRaw":_vm.editRaw,"showAddCatlog":!_vm.notAccess.catalogs[_vm.$auth.roles()].add.includes(
                  _vm.selectMoreCatalog.title
                ),"showEditCatlog":!_vm.notAccess.catalogs[_vm.$auth.roles()].edit.includes(
                  _vm.selectMoreCatalog.title
                ),"chips":[
                {
                  table: 'evaluations',
                  field: 'roles',
                  slot: "item.roles",
                  ifnull: 'name',
                  filter: function (f) { return f; },
                  color: function (v) { return _vm.colorRoles[v.alias]; },
                  title: 'name',
                },
                {
                  table: 'remarks',
                  field: 'entities_evaluation',
                  slot: "item.entities_evaluation",
                  ifnull: 'name',
                  filter: function (f) { return f; },
                  color: function (v) { return 'teal'; },
                  title: 'name',
                } ]},on:{"openEditDialog":_vm.openEditDialog,"openAddDialog":_vm.openAddDialog,"getSubCatalog":_vm.getSubCatalog,"edit":_vm.edit,"multiEdit":_vm.multiEdit,"add":_vm.add,"find":_vm.find,"remove":_vm.remove,"closwDialogAddItem":_vm.closwDialogAddItem,"closeDialogRemove":_vm.closeDialogRemove,"openDialogRemove":_vm.openDialogRemove}})],1):_vm._e()])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }