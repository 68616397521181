var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100",staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"height":"auto"}},[((_vm.fullDataObject && _vm.fullDataObject.length) || _vm.loading)?_c('Tree',{staticClass:"stickyTree",attrs:{"tree":_vm.tree,"loading":_vm.loading},on:{"getBtnClick":_vm.prev}}):_vm._e(),(!_vm.loading)?_c('v-row',{staticClass:"mx-5",attrs:{"no-gutters":"","justify":"start","align":"center"}},[(
          _vm.fullDataObject &&
          _vm.fullDataObject.length &&
          _vm.fullDataObject[0].managers.length
        )?_c('v-col',{staticClass:"grey--text ml-2 subtitle-2 mb-2",attrs:{"cols":"auto"}},[_vm._v(" Менеджер: ")]):_vm._e(),_vm._l((_vm.fullDataObject && _vm.fullDataObject.length
          ? _vm.fullDataObject[0].managers
          : []),function(m){return _c('v-col',{key:m.name,class:{
          'ml-2': true,
          'blue--text': true,
          'subtitle-2': true,
          'mb-2': true,
        },attrs:{"cols":"auto"}},[_c('v-chip',{attrs:{"small":"","color":"blue lighten-4"}},[_vm._v(" "+_vm._s(m.sername + " " + m.name))])],1)}),(
          _vm.fullDataObject &&
          _vm.fullDataObject.length &&
          !_vm.fullDataObject[0].managers.length
        )?_c('v-col',{class:{ 'grey--text': true, 'subtitle-2': true, 'ml-2': true },attrs:{"cols":"auto"}},[_vm._v("Нет менедежра")]):_vm._e()],2):_vm._e(),(
        this.$route.query.action &&
        this.$route.query.action === 'edit' &&
        _vm.fullDataObject &&
        _vm.fullDataObject.length
      )?_c('ActionList',{attrs:{"loadingAction":_vm.loadSubCatalog,"loading":_vm.loading,"list":_vm.list},on:{"getClickListItem":_vm.actionEditObject}}):_vm._e(),(
        this.$route.query.action &&
        ['addEmployee', 'addWorkspace', 'addArea', 'addStoreroom'].includes(
          this.$route.query.action
        )
      )?_c('Form',{attrs:{"formItems":_vm.formItems}}):_vm._e()],1),_c('Bottom',{attrs:{"loadingBottom":_vm.loadingBottom,"active":_vm.activeActionItems,"dataItems":_vm.actionItems,"groping":_vm.actionItems && _vm.actionItems.name === 'evaluations'
        ? 'unit_evaluation'
        : '',"showSave":['admin', 'auditor'].includes(_vm.$auth.roles())},on:{"initActive":_vm.initActive,"apply":_vm.applyAddItem}}),(!(_vm.fullDataObject && _vm.fullDataObject.length) && !_vm.loading)?_c('ErrorOBjectsNo'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }