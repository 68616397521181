<template>
  <div>
    <div class="ma-3" style="position: relative">
      <v-row no-gutters>
        <v-col style="min-width: 300px" sm class="pa-4">
          <v-skeleton-loader
            min-width="300"
            v-if="loadCatalog"
            type="table-heading, list-item-three-line"
          ></v-skeleton-loader>
          <v-row no-gutters>
            <div class="w-100" v-if="!loadCatalog">
              <TableUnit
                :actionsButtons="true"
                :infoReport="infoReport"
                :loadAddRaw="loadAddRaw"
                :loading="loading"
                :itemClasses="itemClasses"
                :dataTable="dataTable[0]"
                :dialogAddItem="dialogAddItem"
                :dialogRemove="dialogRemove"
                :loadSubCatalog="loadSubCatalog"
                :resetFormAddEdit="resetFormAddEdit"
                :initRemoveRaw="removeRaw"
                :initMultiSelected="initMultiSelected"
                :editRaw="editRaw"
                @focusSubSelect="changeSubSelect"
                @openEditDialog="openEditDialog"
                @openAddDialog="openAddDialog"
                @getSubCatalog="getSubCatalog"
                :helpbox="[
                  { color: 'green lighten-4', title: 'администраторы' },
                ]"
                :showAddCatlog="true"
                :showEditCatlog="true"
                :chips="[
                  {
                    table: 'userauth',
                    field: 'objects',
                    slot: `item.objects`,
                    ifnull: 'name',
                    filter: (f) => f,
                    color: (v) => 'amber darken-4',
                    title: 'name',
                  },
                ]"
                @edit="edit"
                @multiEdit="multiEdit"
                @add="add"
                @find="find"
                @remove="remove"
                @closwDialogAddItem="closwDialogAddItem"
                @closeDialogRemove="closeDialogRemove"
                @openDialogRemove="openDialogRemove"
              />
            </div>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Helper from "./../../../plugins/helper";
import TableUnit from "./../../units/table/table.vue";
import commonVal from "./../../paramsCommon";
import ParamsUsers from "./paramsUsers";
import catalogs from "./../../../plugins/catalogs";
export default {
  components: { TableUnit },
  data() {
    return { ...commonVal, ...ParamsUsers };
  },
  async mounted() {
    await this.initData();
  },
  methods: {
    ...Helper,
    ...catalogs,
    changeSubSelect(field) {
      if (field.name === "id_role") {
        let roleModel = this.dataTable[0].add.data.find(
          (f) => f.name === "id_object"
        );
        roleModel.model = null;
      }
      if (field.name === "id_object") {
        let roleModel = this.dataTable[0].add.data.find(
          (f) => f.name === "id_role"
        );
        roleModel = roleModel.model
          ? roleModel.items
              .find((_i) => _i.id === roleModel.model)
              .name.toLowerCase()
          : null;

        field.items = field.allItems;

        if (roleModel && roleModel === "внешний аудитор") {
          field.items = field.allItems.filter(
            (i) => i.type_object.toLowerCase() === "внешний"
          );
        }
      }
    },
    itemClasses(item) {
      return item.role === "admin" ? "green lighten-4" : "";
    },
    async initData() {
      await this.get();
      this.setTitleAppBar("Пользователи");
    },
    async get() {
      let v = this.dataTable[0];
      v.add.data = v.add.data.map((m) => {
        return { ...m, model: null };
      });
      //   this.selectMoreCatalog = {};
      this.loadCatalog = true;
      const dataList = (await this.getCatalog(v.name)).data;
      v.table = dataList.items.map((m) => {
        // delete m.id_role;
        // delete m.role;
        return { ...m, roles: m.role_title };
      });
      this.infoReport.amount = dataList.info.countRows;
      console.log(v.table);
      this.loadCatalog = false;
      v.headers = v.table.length
        ? Object.keys(v.table[0])
            .filter((ff) =>
              [
                "name",
                "sername",
                "login",
                "role_title",
                "contacts",
                "objects",
              ].includes(ff)
            )
            .map((m) => {
              return {
                text: m,
                align: "start",
                sortable: true,
                value: m,
                // groupable: m === 'master_client' ? true : false,
                class: ["nowrap", "grey lighten-5"],
              };
            })
            .concat([
              {
                text: "actions",
                align: "start",
                sortable: false,
                value: "actions",
                class: ["nowrap", "grey lighten-5"],
              },
            ])
        : [];
      console.log(v);
      //   this.selectMoreCatalog = v;
    },
    async remove(removeRaw) {
      this.loadAddRaw = true;
      await this.removeFromCatalog(removeRaw.name_catalog, removeRaw.id);
      await this.get();
      this.loadAddRaw = false;
      this.dialogRemove = false;
      this.removeRaw = !this.removeRaw;
      // deleteItem()
    },
    /**
     * Инициализация редактирвоания
     */
    async openEditDialog(item, selectMoreCatalog) {
      this.dialogAddItem = true;
      this.loadSubCatalog = true;

      if (!item.multi) {
        this.initMultiSelected = !this.initMultiSelected;
        item["name_catalog"] = selectMoreCatalog.name;
        this.editRaw = item;

        selectMoreCatalog.add.data = await Promise.all(
          selectMoreCatalog.add.data.map(async (m) => {
            // console.log(m.name);
            let model = item[this.prepareSubCatalogsNAmeEdit(m.name)];
            // console.log(
            //   m.name,
            //   this.prepareSubCatalogsNAmeEdit(m.name),
            //   item,
            //   model
            // );
            if (m.type === "select") {
              await this.getSubCatalog(m.name);

              // console.log(
              //   "model",
              //   m.name,
              //   this.prepareSubCatalogsNAmeEdit(m.name),
              //   model,
              //   m.items
              // );
              const multiModel = () => {
                return m.items
                  .filter((f) =>
                    model ? model.map((_m) => _m.name).includes(f.name) : false
                  )
                  .map((m) => m.id);
              };

              const sibleModel = () => {
                return m.items.find((f) => {
                  let fullNameItem = f.name;

                  // if (m.name === "id_place")
                  //   fullNameItem = `${f.name} - ${f.region}`;
                  // if (m.name === "id_group")
                  //   fullNameItem = `${f.name} ${f.performance}`;
                  // if (m.name === "id_client")
                  //   fullNameItem = `${f.name} - ${f.master_client}`;
                  // if (m.name === "id_source")
                  //   fullNameItem = `${f.pool} (${f.client}) - ${f.coins}`;

                  // console.log(model, fullNameItem);
                  return model === fullNameItem;
                });
              };
              model = m.multi
                ? multiModel()
                : sibleModel()
                ? sibleModel().id
                : model;
            }

            return {
              ...m,
              model: model,
            };
          })
        );
      } else {
        for (let mi = 0; mi < selectMoreCatalog.add.data.length; mi++) {
          const el = selectMoreCatalog.add.data[mi];
          if (el.type === "select") {
            await this.getSubCatalog(el.name);
          }
        }
      }
      this.loadSubCatalog = false;
    },

    /**
     * Инициализация добавления
     */
    async openAddDialog(data) {
      this.dialogAddItem = true;
      this.loadSubCatalog = true;
      console.log(data.add.data, this.dataTable);
      data.add.data = await Promise.all(
        data.add.data.map(async (m) => {
          if (m.type === "select") {
            await this.getSubCatalog(m.name);
          }
          return {
            ...m,
          };
        })
      );

      this.loadSubCatalog = false;
    },

    async edit(name, data) {
      console.log(data);
      data = data.reduce((acc, el) => {
        console.log(el.model);
        return {
          ...acc,
          ...{
            [el.name]:
              el.multi && el.model
                ? el.model.map((m) => (m.id ? m.id : m))
                : el.model,
          },
        };
      }, {});
      const id = this.editRaw.id;
      this.loadAddRaw = true;
      await this.updateToCatalog(name, data, id);
      await this.get();
      this.loadAddRaw = false;
      this.dialogAddItem = false;
      this.resetFormAddEdit = !this.resetFormAddEdit;
      this.editRaw = null;
    },

    async multiEdit(name, data, ids) {
      console.log(data);
      data = data
        .filter((f) => f.multiEditField)
        .reduce((acc, el) => {
          return {
            ...acc,
            ...{
              [el.name]:
                el.multi && el.model
                  ? el.model.map((m) => (m.id ? m.id : m))
                  : el.model,
            },
          };
        }, {});
      this.loadAddRaw = true;

      await this.multiUpdateToCatalog(name, {
        data: data,
        ids: ids,
      });
      await this.get();
      this.loadAddRaw = false;
      this.dialogAddItem = false;
      this.resetFormAddEdit = !this.resetFormAddEdit;
      this.editRaw = null;
      this.initMultiSelected = !this.initMultiSelected;
    },

    async getSubCatalog(name) {
      this.loadSubCatalog = true;
      console.log(name);
      this.itemsSub = (
        await this.getCatalog(this.prepareSubCatalogsNAme(name))
      ).data.items;

      this.dataTable.forEach((catalog) => {
        if (catalog.add)
          catalog.add.data.forEach((selects) => {
            if (selects.name === name) {
              selects.items = this.itemsSub;
              selects.allItems = this.itemsSub;
              // if (selects.multi)
              // selects.model = this.itemsSub.filter((f) =>
              //   selects.model.split(",").includes(f.name)
              // );
              // console.log(nameSelect, this.catalogs);
            }
          });
      });
      this.loadSubCatalog = false;

      return this.itemsSub;
    },

    async add(name, data) {
      data = data.reduce((acc, el) => {
        return {
          ...acc,
          ...{
            [el.name]:
              el.model && el.multi ? el.model.map((m) => m.id) : el.model,
          },
        };
      }, {});
      this.loadAddRaw = true;
      await this.addToCatalog(name, data);
      await this.get();
      this.loadAddRaw = false;
      this.dialogAddItem = false;
      this.resetFormAddEdit = !this.resetFormAddEdit;
    },

    async find() {
      let v = this.dataTable[0];
      console.log(v);
      // this.selectMoreCatalog = {};
      const name = this.prepareSubCatalogsNAme(v.name);
      const data = v.add.data
        .filter((f) => f.filter)
        .reduce((acc, el) => {
          return {
            ...acc,
            ...{
              [el.name]:
                el.multi && el.model
                  ? el.model.map((m) => (m.id ? m.id : m))
                  : el.model,
            },
          };
        }, {});
      this.loading = true;
      this.loadingFind = true;
      const dataFind = (await this.findCatalog(name, data)).data;
      v.table = dataFind.items.map((m) => {
        // delete m.id_role;
        // delete m.role;
        return { ...m, roles: m.role_title };
      });
      this.infoReport.amount = dataFind.info.countRows;
      this.loadingFind = false;
      this.loading = false;
      v.headers = v.table.length
        ? Object.keys(v.table[0])
            .filter((ff) =>
              [
                "name",
                "sername",
                "login",
                "role_title",
                "contacts",
                "objects",
              ].includes(ff)
            )
            .map((m) => {
              return {
                text: m,
                align: "start",
                sortable: true,
                value: m,
                class: ["nowrap", "grey lighten-5"],
              };
            })
            .concat([
              {
                text: "actions",
                align: "start",
                sortable: false,
                value: "actions",
                class: ["nowrap", "grey lighten-5"],
              },
            ])
        : [];
      console.log(v);
      this.selectMoreCatalog = v;
    },
  },
};
</script>