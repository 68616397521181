import Vue from 'vue';
import Router from 'vue-router';
import Login from '../components/auth/Login.vue';
// import Register from '../components/auth/Register.vue';
import home from '../components/views/home/home.vue';

import audit from '../components/views/audit/audit.vue';
import object from '../components/views/object/object.vue';
import users from '../components/views/users/users.vue';
import settings from '../components/auth/settings';
import catalogs from '../components/views/settings/catalogs/catalogs.vue';
import addAudit from '../components/views/addAudit/addAudit.vue';
import addObject from '../components/views/addObject/addObject.vue';
import auditList from '../components/views/auditList/auditList.vue';
import objectList from '../components/views/objectList/objectList.vue';
import objectRegistry from '../components/views/objectRegistry/objectRegistry.vue';
// import sources from '../components/public/sources/sources';
// --------------------------------------------------------------------
// vue-router CONFIGURATION
// --------------------------------------------------------------------

Vue.use(Router);

const router = new Router({
    mode: 'history',
    // base: '/',
    routes: [
        {
            path: '/',
            name: 'home',
            component: home,
            meta: {
                auth: true,
            },
        },
        {
            path: '/addAudit',
            name: 'addAudit',
            component: addAudit,
            // meta: {
            //     auth: true,
            // },
        },
        {
            path: '/addObject',
            name: 'addObject',
            component: addObject,
            meta: {
                auth: 'admin',
            },
        },
        {
            path: '/audit',
            name: 'audit',
            component: audit,
            // meta: {
            //     auth: true,
            // },
        },
        {
            path: '/audit',
            name: 'audit',
            component: audit,
            // meta: {
            //     auth: true,
            // },
        },
        {
            path: '/object',
            name: 'object',
            component: object,
            // meta: {
            //     auth: true,
            // },
        },
        {
            path: '/auditList',
            name: 'auditList',
            component: auditList,
            // meta: {
            //     auth: true,
            // },
        },
        {
            path: '/objectList',
            name: 'objectList',
            component: objectList,
            // meta: {
            //     auth: true,
            // },
        },

        {
            path: '/objectRegistry',
            name: 'objectRegistry',
            component: objectRegistry,
            meta: {
                auth: 'admin',
            },
        },

        {
            path: '/users',
            name: 'users',
            component: users,
            meta: {
                auth: 'admin',
            },
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
        },
        // {
        //     path: '/register',
        //     name: 'register',
        //     component: Register,
        // },
        // {
        //     path: '/editor',
        //     name: 'Редактор',
        //     component: editor
        // },
        // {
        //     path: '/contacts',
        //     name: 'Контакты',
        //     component: contacts
        // },
        {
            path: '/settings',
            name: 'Настройки',
            component: settings,
            // meta: {
            //     auth: true,
            // },
        },
        {
            path: '/settings/catalogs',
            name: 'Настройка каталогов',
            component: catalogs,
            // meta: {
            //     auth: true,
            // },
        },
        // {
        //     path: '/',
        //     name: 'main-user',
        //     component: User,
        //     children: [
        //         {
        //             path: '',
        //             name: 'user1',
        //             meta: {
        //                 auth: true,
        //             },
        //             component: Info,
        //         },
        //         {
        //             path: 'user',
        //             name: 'user2',
        //             meta: {
        //                 auth: 'ROLE_ADMIN',
        //             },
        //             component: Info,
        //         },
        //     ],
        // },
        // {
        //     path: '/admin',
        //     meta: {
        //         auth: true,
        //     },
        //     component: Admin,
        //     children: [
        //         {
        //             path: '',
        //             name: 'admin1',
        //             meta: {
        //                 auth: ['ROLE_ADMIN'],
        //             },
        //             component: Info,
        //         },
        //         {
        //             path: 'auth',
        //             name: 'admin2',
        //             meta: {
        //                 auth: ['ROLE_UNKNOWN'],
        //             },
        //             component: Info,
        //         },
        //     ],
        // },
        // {
        //     path: '/logout',
        //     component: Logout,
        // },
        // {
        //     path: '*',
        //     redirect: '/',
        // },
    ],
});

(Vue).router = router;

export default router;