<template>
  <div style="position: relative">
    <div class="mt-3" v-if="!loading">
      <a
        @click="hiddenFilter = hiddenFilter === 0 ? -1 : 0"
        class="ml-6 caption blue--text text--darken-2"
      >
        {{ hiddenFilter === 0 ? "Свернуть" : "Развернуть" }} фильтр
        <v-icon color="blue darken-2" size="20">{{
          hiddenFilter === 0 ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon></a
      >

      <v-expansion-panels class="mt-2" flat v-model="hiddenFilter">
        <v-expansion-panel>
          <v-expansion-panel-content>
            <v-row no-gutters class="grey lighten-5">
              <Search
                :loadDataFilter="loadSubCatalog"
                @getCatalogFromBlock="getSubCatalog"
                :filter="findItems"
                @find="find"
              />
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <ActionList
      :loadingAction="false"
      :loading="loading"
      @getClickListItem="clicckObject"
      :list="list"
    />
    <ErrorOBjectsNo v-if="!list.items.length && !loading" />
  </div>
</template>
<script>
import Helper from "../../../plugins/helper";
import catalogs from "./../../../plugins/catalogs";
import ActionList from "./../../units/actionList/actionList.vue";
import Search from "./../../units/search/search.vue";
import ErrorOBjectsNo from "./../../views/errors/notObject.vue";
import ParamsObjectList from "./paramsObjectList";
import commonVal from "./../../paramsCommon";
export default {
  components: {
    ActionList,
    ErrorOBjectsNo,
    Search,
  },
  data() {
    return {
      ...ParamsObjectList,
      loading: commonVal.loading,
      loadSubCatalog: commonVal.loadSubCatalog,
    };
  },
  async mounted() {
    this.setTitleAppBar("Все объекты");
    await this.initData();
  },

  methods: {
    ...Helper,
    ...catalogs,

    async getSubCatalog(name) {
      this.loadSubCatalog = true;

      this.itemsSub = (
        await this.getCatalog(this.prepareSubCatalogsNAme(name))
      ).data.items;

      this.findItems.forEach((selects) => {
        if (selects.name === name) {
          selects.items = this.itemsSub;
        }
      });

      this.loadSubCatalog = false;

      return this.itemsSub;
    },

    async find() {
      await this.get();
    },
    async initData() {
      await this.get();
    },
    clicckObject(o) {
      this.$router.push({ path: `object?id=${o.id}&action=edit` });
    },
    async get() {
      this.loading = true;
      const allObjects = (
        await this.findCatalog("objects", {
          id: null,
          name: this.findItems.find((f) => f.name === "name").model,
          id_user: ["manager", "client", "external_auditor"].includes(
            this.$auth.roles()
          )
            ? this.$auth.user().id
            : null,
          id_status_object: this.findItems.find(
            (f) => f.name === "id_status_object"
          ).model,
        })
      ).data.items;
      this.list.items = allObjects.map((m) => {
        return {
          id: m.id,
          name: m.name,
          title: m.name,
          more: true,
          managers: m.managers,
          status_object: m.status_object,
          color:
            m.type_object.toLowerCase() === "свой"
              ? "custom_red"
              : "custom_yellow",
          icon: m.type_object.toLowerCase() === "свой" ? "" : "mdi-open-in-new",
        };
      });
      this.loading = false;
    },
  },
};
</script>
