<script>
import { HorizontalBar } from "vue-chartjs";

export default {
  extends: HorizontalBar,
  props: ["chartdata", "options"],
  mounted() {
    // console.log(this.chartdata);
    this.renderChart(this.chartdata, this.options);
  },
  //   watch: {
  //     chartdata: {
  //       handler(v) {
  //         console.log(v);
  //         this.renderChart(v, this.options);
  //       },
  //       deep: true,
  //     },
  //   },
};
</script>