import { COLORS_ROLE_PROJECT } from "../constants";

export default {
    // loadFinding: false,
    optionGoTo: {
        duration: 370,
        offset: 80,
    },


    bottomPanelChoiseItem: false,
    bottomPanelItems: [],
    colorRoles: COLORS_ROLE_PROJECT,
    dialogErrorPermissions: false,
    removeRaw: null,
    editRaw: null,
    resetFormAddEdit: false,
    initMultiSelected: false,
    loading: false,
    loadingBottom: false,
    loadingAction: false,
    dialogAddItem: false,
    dialogRemove: false,
    loadSubCatalog: false,
    loadAddRaw: false,
    infoReport: {
        amount: 0,
        sumShare1d: 0,
    },
    dialogHelpTooltip: false,
    helpTooltip: '',
    tempSubItems: [],
    addTempData: false,
    dialogAddTempItem: false,

    notAccess: {
        menu: {
            'admin': ['Добавить аудит'],
            'auditor': ['Добавить объект', 'Пользователи', 'События объектов'],
            'rpo': ['Добавить объект', 'Пользователи', 'События объектов'],
            'engineer': ['Добавить объект', 'Пользователи', 'Добавить аудит', 'События объектов'],
            'general_manager': ['Добавить объект', 'Пользователи', 'Добавить аудит', 'События объектов'],
            'manager': ['Добавить аудит', 'Добавить объект', 'Пользователи', 'События объектов'],
            'main_manager': ['Добавить аудит', 'Найтроки', 'Настройка справочников', 'Добавить объект', 'Пользователи', 'События объектов'],
            'client': ['Добавить аудит', 'Добавить объект', 'Пользователи', 'События объектов', 'Настройки', 'Настройка справочников'],
            'external_auditor': ['Добавить объект', 'Пользователи', 'События объектов'],
        },
        catalogs: {
            'admin': {
                add: [],
                edit: [],
                view: [],
            },
            'auditor': {
                add: ['Замечания', 'Типы объектов', 'Роли', 'Типы оценок', "Веса типов оценок"],
                edit: ['Замечания', 'Типы объектов', 'Роли', 'Типы оценок', "Веса типов оценок"],
                view: ['Типы объектов', 'Роли', 'Типы оценок'],
            },
            'rpo': {
                add: ['Блоки оценок', 'Что оцениваем', 'Замечания', 'Типы объектов', 'Участки', 'Кладовые', 'Поверхности', 'Рабочие места', 'Сотрудники', 'Роли', 'Типы оценок', "Веса типов оценок"],
                edit: ['Блоки оценок', 'Что оцениваем', 'Замечания', 'Типы объектов', 'Участки', 'Кладовые', 'Поверхности', 'Рабочие места', 'Сотрудники', 'Роли', 'Типы оценок', "Веса типов оценок"],
                view: ['Типы объектов', 'Роли', 'Типы оценок']
            },
            'engineer': {
                add: ['Блоки оценок', 'Что оцениваем', 'Замечания', 'Типы объектов', 'Участки', 'Кладовые', 'Поверхности', 'Рабочие места', 'Сотрудники', 'Роли', 'Типы оценок', "Веса типов оценок"],
                edit: ['Блоки оценок', 'Что оцениваем', 'Замечания', 'Типы объектов', 'Участки', 'Кладовые', 'Поверхности', 'Рабочие места', 'Сотрудники', 'Роли', 'Типы оценок', "Веса типов оценок"],
                view: ['Типы объектов', 'Участки', 'Кладовые', 'Поверхности', 'Рабочие места', 'Сотрудники', 'Роли', 'Типы оценок', "Веса типов оценок"]
            },

            'general_manager': {
                add: ['Блоки оценок', 'Что оцениваем', 'Замечания', 'Типы объектов', 'Участки', 'Кладовые', 'Поверхности', 'Рабочие места', 'Сотрудники', 'Роли', 'Типы оценок', "Веса типов оценок"],
                edit: ['Блоки оценок', 'Что оцениваем', 'Замечания', 'Типы объектов', 'Участки', 'Кладовые', 'Поверхности', 'Рабочие места', 'Сотрудники', 'Роли', 'Типы оценок', "Веса типов оценок"],
                view: ['Участки', 'Кладовые', 'Поверхности', 'Рабочие места', 'Сотрудники', 'Роли', 'Типы оценок']
            },

            'manager': {
                add: ['Блоки оценок', 'Что оцениваем', 'Замечания', 'Типы объектов', 'Участки', 'Кладовые', 'Поверхности', 'Рабочие места', 'Сотрудники', 'Роли', 'Типы оценок', "Веса типов оценок"],
                edit: ['Блоки оценок', 'Что оцениваем', 'Замечания', 'Типы объектов', 'Участки', 'Кладовые', 'Поверхности', 'Рабочие места', 'Сотрудники', 'Роли', 'Типы оценок', "Веса типов оценок"],
                view: ['Блоки оценок', 'Что оцениваем', 'Замечания', 'Типы объектов', 'Роли', 'Типы оценок', "Веса типов оценок"]
            },

            'main_manager': {
                add: ['Блоки оценок', 'Что оцениваем', 'Замечания', 'Типы объектов', 'Участки', 'Кладовые', 'Поверхности', 'Рабочие места', 'Сотрудники', 'Роли', 'Типы оценок', "Веса типов оценок"],
                edit: ['Блоки оценок', 'Что оцениваем', 'Замечания', 'Типы объектов', 'Участки', 'Кладовые', 'Поверхности', 'Рабочие места', 'Сотрудники', 'Роли', 'Типы оценок', "Веса типов оценок"],
                view: ['Блоки оценок', 'Что оцениваем', 'Замечания', 'Типы объектов', 'Участки', 'Кладовые', 'Поверхности', 'Рабочие места', 'Сотрудники', 'Роли', 'Типы оценок', "Веса типов оценок"]
            },

            'client': {
                add: ['Блоки оценок', 'Что оцениваем', 'Замечания', 'Типы объектов', 'Участки', 'Кладовые', 'Поверхности', 'Рабочие места', 'Сотрудники', 'Роли', 'Типы оценок', "Веса типов оценок"],
                edit: ['Блоки оценок', 'Что оцениваем', 'Замечания', 'Типы объектов', 'Участки', 'Кладовые', 'Поверхности', 'Рабочие места', 'Сотрудники', 'Роли', 'Типы оценок', "Веса типов оценок"],
                view: ['Типы объектов', 'Участки', 'Кладовые', 'Поверхности', 'Рабочие места', 'Сотрудники', 'Роли', 'Типы оценок']
            },

            'external_auditor': {
                add: ['Блоки оценок', 'Что оцениваем', 'Замечания', 'Типы объектов', 'Участки', 'Кладовые', 'Поверхности', 'Рабочие места', 'Сотрудники', 'Роли', 'Типы оценок', "Веса типов оценок"],
                edit: ['Блоки оценок', 'Что оцениваем', 'Замечания', 'Типы объектов', 'Участки', 'Кладовые', 'Поверхности', 'Рабочие места', 'Сотрудники', 'Роли', 'Типы оценок', "Веса типов оценок"],
                view: ['Типы объектов', 'Участки', 'Кладовые', 'Поверхности', 'Рабочие места', 'Сотрудники', 'Роли', 'Типы оценок']
            },


        }
    },

    rules: {
        max25chars: v => v.length <= 25 || 'Вы превысили лимит символов',
        required: (value) => (value === false ? true : (Array.isArray(value) ? !!value.length : !!value)) || "Обязательное поле.",
        // min: (v) => v.length >= 50 || "Min 8 characters",
        emailMatch: (value) =>
            /.+@.+\..+/.test(value) ||
            `The email and password you entered don't match`,
        doubleLogin: (label_input, model) => label_input.items && !label_input.items.find(
            (fe) => fe.login === model
        ) || "Пользователь с таким логином уже есть в системе!"
    },
}