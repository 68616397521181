var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"ma-3",staticStyle:{"position":"relative"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-4",staticStyle:{"min-width":"300px"},attrs:{"sm":""}},[(_vm.loadCatalog)?_c('v-skeleton-loader',{attrs:{"min-width":"300","type":"table-heading, list-item-three-line"}}):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[(!_vm.loadCatalog)?_c('div',{staticClass:"w-100"},[_c('TableUnit',{attrs:{"actionsButtons":true,"infoReport":_vm.infoReport,"loadAddRaw":_vm.loadAddRaw,"loading":_vm.loading,"itemClasses":_vm.itemClasses,"dataTable":_vm.dataTable[0],"dialogAddItem":_vm.dialogAddItem,"dialogRemove":_vm.dialogRemove,"loadSubCatalog":_vm.loadSubCatalog,"resetFormAddEdit":_vm.resetFormAddEdit,"initRemoveRaw":_vm.removeRaw,"initMultiSelected":_vm.initMultiSelected,"editRaw":_vm.editRaw,"helpbox":[
                { color: 'green lighten-4', title: 'администраторы' } ],"showAddCatlog":true,"showEditCatlog":true,"chips":[
                {
                  table: 'userauth',
                  field: 'objects',
                  slot: "item.objects",
                  ifnull: 'name',
                  filter: function (f) { return f; },
                  color: function (v) { return 'amber darken-4'; },
                  title: 'name',
                } ]},on:{"focusSubSelect":_vm.changeSubSelect,"openEditDialog":_vm.openEditDialog,"openAddDialog":_vm.openAddDialog,"getSubCatalog":_vm.getSubCatalog,"edit":_vm.edit,"multiEdit":_vm.multiEdit,"add":_vm.add,"find":_vm.find,"remove":_vm.remove,"closwDialogAddItem":_vm.closwDialogAddItem,"closeDialogRemove":_vm.closeDialogRemove,"openDialogRemove":_vm.openDialogRemove}})],1):_vm._e()])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }