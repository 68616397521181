var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c('v-overlay',{attrs:{"opacity":"0.8","z-index":"12","value":_vm.loadingAction}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":"","justify":"center"}},[_c('span',{staticClass:"subhead"},[_vm._v("Идет загрузка данных...")])])],1),_c('v-form',{ref:"formAddRawCatalog"},[_c('v-row',_vm._l((_vm.formItems.items),function(item){return _c('v-col',{key:item.name,attrs:{"cols":"12","sm":item.block ? 12 : 6}},[_c('v-col',{staticClass:"pa-0 mb-1",attrs:{"cols":"auto"}},[(item.label && !_vm.loadSubCatalog)?_c('span',{staticClass:"pa-1 custom_blue"},[_vm._v(_vm._s(item.label))]):_vm._e(),(_vm.loadSubCatalog)?_c('v-skeleton-loader',{attrs:{"min-width":"200","width":"200","type":"text"}}):_vm._e()],1),_c('v-col',{staticClass:"pa-0 mb-0",attrs:{"cols":"12"}},[(_vm.loadSubCatalog)?_c('v-skeleton-loader',{staticStyle:{"width":"100%"},attrs:{"min-width":"300","type":"heading"}}):_vm._e(),(item.type === 'text' && !_vm.loadSubCatalog)?_c('v-text-field',{attrs:{"dense":"","rules":item.required ? [_vm.rules.required] : [],"outlined":"","background-color":"#e7f6fd","hide-details":"auto"},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}}):_vm._e(),(item.type === 'select' && !_vm.loadSubCatalog)?_c('v-autocomplete',{attrs:{"multiple":item.multi,"small-chips":"","deletable-chips":"","loading":_vm.loadSubCatalog,"dense":"","item-text":item.itemText,"rules":item.required ? [_vm.rules.required] : [],"outlined":"","hide-details":"auto","background-color":"#e7f6fd","items":item.items,"item-value":"id","return-object":""},on:{"change":function (e) {
                item.model = e ? e.id : null;
                if (item.multi) {
                  item.model = e;
                }
              }},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}}):_vm._e(),(item.type === 'textarea' && !_vm.loadSubCatalog)?_c('v-textarea',{attrs:{"dense":"","rules":item.required ? [_vm.rules.required] : [],"outlined":"","background-color":"#e7f6fd","auto-grow":"","hide-details":"auto","rows":"2"},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}}):_vm._e(),(item.type === 'date' && !_vm.loadSubCatalog)?_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","background-color":"#e7f6fd","rules":item.required ? [_vm.rules.required] : [],"value":_vm.dateText(item.model),"prepend-inner-icon":"mdi-calendar","hide-details":"auto"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-date-picker',{attrs:{"no-title":""},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})],1):_vm._e()],1)],1)}),1)],1),(!_vm.loadSubCatalog)?_c('v-row',{staticClass:"mt-6",attrs:{"no-gutters":"","justify":"end"}},[_c('v-btn',{staticClass:"new-btn",staticStyle:{"color":"#000 !important"},attrs:{"color":"green lighten-2","disabled":!_vm.formItems.items
          .filter(function (f) { return f.required; })
          .every(function (e) { return (e.multi && e.model ? e.model.length : e.model); }),"elevation":"0"},on:{"click":function($event){return _vm.actionBtn(_vm.formItems.items)}}},[_vm._v("Готово")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }